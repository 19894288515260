import React, { useState, useEffect } from "react";
import Sidebar from "../../../../components/sidebar";
import Header from "../../../../components/Header/index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark, faCheck } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import {
  listUsers,
  deleteUser,
  getUserDetails,
  updateUser,
} from "../../../../actions/userActions";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { useNavigate, useLocation } from "react-router-dom";
import { USER_UPDATE_RESET } from "../../../../constants/userConstant";

function Index() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
    const { t } = useTranslation();

  const userList = useSelector((state) => state.userList);
  const { loading, error, users } = userList;
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const userDelete = useSelector((state) => state.userDelete);
  const { success } = userDelete;
  
  const userDetails = useSelector((state) => state.userDetails);
  const { user } = userDetails;
  const { errorUpdate, loadingUpdate, successUpdate } = useSelector(
    (state) => state.userUpdate
  );

 const [name, setName] = useState(user ? user.data?.name : "");
 const [email, setEmail] = useState(user ? user.data?.email : "");
 const [isAdmin, setAdmin] = useState(user ? user.data?.isAdmin : "");
 const [currentPage, setCurrentPage] = useState(1);
 const [postsPerPage, setPostsPerPage] = useState(4);

useEffect(() => {
  if (user) {
    setName(user.data?.name);
    setEmail(user.data?.email);
    setAdmin(user.data?.isAdmin);
  } dispatch(listUsers());
}, [user]);

  useEffect(() => {
     dispatch(listUsers());
    if (userInfo && userInfo.data.isAdmin) {
      dispatch(listUsers());
    } else {
      navigate("/login");
    }
   
  }, [dispatch, navigate, success, userInfo]);
 

  const getbyId = (id) => {
     dispatch(getUserDetails(id));
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (user && user.data && user.data._id) {
      console.log(name);
      console.log(email);
       
      dispatch(
        updateUser({ data: { _id: user.data._id, name, email, isAdmin } })
      );
    }
    dispatch(listUsers());
  }; 
  const deleteHandler = () => {
    if (user && user.data && user.data._id) {
      dispatch(deleteUser(user.data._id));
    }
  };
  const lastPostIndex = currentPage * postsPerPage;
  const fristPostIndex = lastPostIndex - postsPerPage;
   
   
  // console.log(p); 
  return (
    <body>
      <div className="main-wrapper">
        <Header />
        <br />
        <div className="breadcrumb-bar">
          <div className="container-fluid">
            <div className="row align-items-center">
              <div className="col-md-12 col-12">
                <nav aria-label="breadcrumb" className="page-breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="#/" className="a">
                        {t("Home")}{" "}
                      </a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Dashboard
                    </li>
                  </ol>
                </nav>
                <h2 className="breadcrumb-title">Users</h2>
              </div>
            </div>
          </div>
        </div>
        <div className="page-wrapper">
          <div className="content container-fluid">
            <div className="row">
              <Sidebar />
              <div className="col-md-7 col-lg-8 col-xl-9">
                <div className="row">
                  <div className="col-sm-12">
                    <div className="card">
                      <div className="card-body">
                        <div className="table-responsive">
                          <table className="datatable table table-hover table-center mb-0">
                            <thead>
                              <tr>
                                <th>ID</th>
                                <th className="text-center">NAME</th>
                                <th className="text-center">EMAIL</th>
                                <th>ADMIN</th>
                                <th></th>{" "}
                              </tr>
                            </thead>
                            <tbody>
                              {users && users.data
                                ? users?.data.map((user, index) => (
                                    <tr>
                                      {" "}
                                      <td>{index + 1}</td>
                                      <td className="text-center">
                                        <h2 className="table-avatar">
                                          {user.name}
                                        </h2>
                                      </td>
                                      <td className="text-center">
                                        {user.email}
                                      </td>
                                      <td>
                                        {user.isAdmin ? (
                                          <span className="accept">
                                            <FontAwesomeIcon icon={faCheck} />
                                          </span>
                                        ) : (
                                          <span className="reject">
                                            <FontAwesomeIcon icon={faXmark} />
                                          </span>
                                        )}
                                      </td>
                                      <td className="text-end">
                                        <div className="actions">
                                          <a
                                            data-bs-toggle="modal"
                                            href="#edit_invoice_report"
                                            className="btn btn-sm bg-success-light me-2"
                                            onClick={() => getbyId(user._id)}
                                          >
                                            <i className="fe fe-pencil" /> Edit
                                          </a>
                                          <a
                                            className="btn btn-sm bg-danger-light"
                                            data-bs-toggle="modal"
                                            href="#delete_modal"
                                            onClick={() => getbyId(user._id)}
                                          >
                                            <i className="fe fe-trash" /> Delete
                                          </a>
                                        </div>
                                      </td>
                                    </tr>
                                  ))
                                : " "}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="edit_invoice_report"
        aria-hidden="true"
        role="dialog"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Edit User</h5>
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={submitHandler}>
                <div className="row form-row">
                  <div className="col-12 ">
                    <div className="form-group">
                      <label>Name</label>
                      <input
                        type="text"
                        className="form-control"
                        defaultValue={user?.data?.name || ""}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-12 ">
                    <div className="form-group">
                      <label>Email</label>
                      <input
                        type="text"
                        className="form-control"
                        defaultValue={user?.data?.email || ""}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-12 ">
                    <div className="form-group">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="agreeCheckboxUser"
                        id="agree_checkbox_user"
                        checked={isAdmin}
                        onChange={(e) => setAdmin(e.target.checked)}
                        style={{ width: "20px", height: "20px" }}
                      />{" "}
                      &nbsp;
                      <label
                        class="form-check-label"
                        for="agree_checkbox_user"
                        style={{ marginTop: "3px" }}
                      >
                        Is Admin
                      </label>
                    </div>
                  </div>
                </div>
                <button
                  type="submit"
                  className="btn btn-primary btn-block w-100"
                  data-bs-dismiss="modal"
                >
                  Save Changes
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="delete_modal"
        aria-hidden="true"
        role="dialog"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content" style={{ textAlign: "center" }}>
            <div className="modal-body">
              <div
                className="form-content p-2"
                style={{ alignContent: "center" }}
              >
                <h4 className="modal-title">Delete</h4>
                <p className="mb-4">Are you sure want to delete?</p>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => deleteHandler()}
                  data-bs-dismiss="modal"
                >
                  Delete{" "}
                </button>
                <button
                  type="button"
                  className="btn btn-danger"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </body>
  );
}

export default Index;
