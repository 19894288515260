import { FISHE_LIST_REQUEST,
         FISHE_LIST_SUCCESS,
         FISHE_LIST_FAIL,
         
         FISHE_DETAILS_REQUEST,
         FISHE_DETAILS_SUCCESS,
         FISHE_DETAILS_FAIL,

         FISHE_DELETE_REQUEST,
         FISHE_DELETE_SUCCESS,
         FISHE_DELETE_FAIL,

         FISHE_CREATE_REQUEST,
         FISHE_CREATE_SUCCESS,
         FISHE_CREATE_FAIL,
         FISHE_CREATE_RESET,

         FISHE_CREATE_REVIEW_REQUEST,
         FISHE_CREATE_REVIEW_SUCCESS,
         FISHE_CREATE_REVIEW_FAIL,
         FISHE_CREATE_REVIEW_RESET,

         FISHE_UPDATE_REQUEST,
         FISHE_UPDATE_SUCCESS,
         FISHE_UPDATE_FAIL,
         FISHE_UPDATE_RESET,

         FISHE_TOP_REQUEST,
         FISHE_TOP_SUCCESS,
         FISHE_TOP_FAIL,
         FISHE_TOP_RESET,

        Fish_GET_BY_TYPE_REQUEST,
        Fish_GET_BY_TYPE_SUCCESS,
        Fish_GET_BY_TYPE_FAIL,
        
        COUNT_FISH_REQUEST,
        COUNT_FISH_SUCCESS,
        COUNT_FISH_FAIL,

} from '../constants/fisheConstant'

export const fisheListReducers = (state ={fishes:[]},action)=>{
    switch(action.type){
        case FISHE_LIST_REQUEST:
            return {loading:true, fishes:[]}
        case FISHE_LIST_SUCCESS:
            return {
                loading:false,
                fishes:action.payload.fishes,
                page:action.payload.page,
                pages:action.payload.pages}
        case FISHE_LIST_FAIL:
            return {loading:false, error:action.payload}
        default:
            return state
    }

}

export const fisheDetailReducers = (state ={fishe:{reviews:[]}},action)=>{
    switch(action.type){
        case FISHE_DETAILS_REQUEST:
            return {loading:true, ...state}
        case FISHE_DETAILS_SUCCESS:
            return {loading:false, fishe:action.payload}
        case FISHE_DETAILS_FAIL:
            return {loading:false, error:action.payload}
        default:
            return state
    }

}

export const fisheDeleleReducers = (state ={},action)=>{
    switch(action.type){
        case FISHE_DELETE_REQUEST:
            return {loading:true}
        case FISHE_DELETE_SUCCESS:
            return {loading:false, success:true}
        case FISHE_DELETE_FAIL:
            return {loading:false, error:action.payload}
        default:
            return state
    }

}


export const fisheCreateReducers = (state ={},action)=>{
    switch(action.type){
        case FISHE_CREATE_REQUEST:
            return {loading:true}
        case FISHE_CREATE_SUCCESS:
            return {loading:false, success:true, fishe:action.payload}
        case FISHE_CREATE_FAIL:
            return {loading:false, error:action.payload}
        case FISHE_CREATE_RESET:
            return {}
        default:
            return state
    } 

}


export const fisheUpdateReducers = (state ={fishe: {}},action)=>{
    switch(action.type){
        case FISHE_UPDATE_REQUEST:
            return {loading:true}
        case FISHE_UPDATE_SUCCESS:
            return {loading:false, success:true, fishe:action.payload}
        case FISHE_UPDATE_FAIL:
            return {loading:false, error:action.payload}
        case FISHE_UPDATE_RESET:
            return {fishe: {}}
        default:
            return state
    } 

}

export const fisheReviewCreateReducers = (state ={},action)=>{
    switch(action.type){
        case FISHE_CREATE_REVIEW_REQUEST:
            return {loading:true}
        case FISHE_CREATE_REVIEW_SUCCESS:
            return {loading:false, success:true}
        case FISHE_CREATE_REVIEW_FAIL:
            return {loading:false, error:action.payload}
        case FISHE_CREATE_REVIEW_RESET:
            return { }
        default:
            return state
    } 

}


export const fisheTopRatedReducers = (state ={fishes:[]},action)=>{
    switch(action.type){
        case FISHE_TOP_REQUEST:
            return {loading:true,fishes:[]}
        case FISHE_TOP_SUCCESS:
            return {loading:false, fishes:action.payload}
        case FISHE_TOP_FAIL:
            return {loading:false, error:action.payload}
        default:
            return state
    } 

}

export const ListfishbyTypeReducers = (state = { fish: [] }, action) => {
  switch (action.type) {
    case Fish_GET_BY_TYPE_REQUEST:
      return { loading: true, fish: [] };
    case Fish_GET_BY_TYPE_SUCCESS:
      return {
        loading: false,
        fish: action.payload,
        message: "success",
        // page: action.payload.page,
        // pages: action.payload.pages,
      };
    case Fish_GET_BY_TYPE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const countfishReducers = (state = { count:0 }, action) => {
  switch (action.type) {
    case COUNT_FISH_REQUEST:
      return { loading: true, count: 0 };
    case COUNT_FISH_SUCCESS:
      return {
        loading: false,
        count: action.payload,
        // message: "success",
      };
    case COUNT_FISH_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};