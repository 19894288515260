import React ,{useEffect}from 'react'
import Sidebar from "../../../../components/sidebar"
import Header from "../../../../components/Header/index";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCreditCard,
  faShrimp,
  faFish,
  faMoneyBillTransfer,
} from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { listUsers } from "../../../../actions/userActions";
import { useNavigate, useLocation, Link } from "react-router-dom";
import {
  listOrders,
} from "../../../../actions/orderAction";
import { countFish } from "../../../../actions/fisheActions";

function Index() {
   const dispatch = useDispatch();
   const navigate = useNavigate();

   const userList = useSelector((state) => state.userList);
   const { loading, error, users } = userList;
   const userLogin = useSelector((state) => state.userLogin);
   const { userInfo } = userLogin;

  // const userDelete = useSelector((state) => state.userDelete);
   //const  } = userDelete;

     const orderList = useSelector((state) => state.orderList);
    const { orders } = orderList;
     const {count } = useSelector((state) => state.countfish);
   useEffect(() => {
     if (userInfo && userInfo.data.isAdmin) {
       dispatch(listUsers());
       dispatch(listOrders());
      dispatch(countFish());
     } else {
       navigate("/login");
     }
   }, [dispatch, navigate, userInfo]);

  return (
    <body>
      <div className="main-wrapper">
        <Header />
        <br />
        <div className="breadcrumb-bar">
          <div className="container-fluid">
            <div className="row align-items-center">
              <div className="col-md-12 col-12">
                <nav aria-label="breadcrumb" className="page-breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="index.html">Home</a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Dashboard
                    </li>
                  </ol>
                </nav>
                <h2 className="breadcrumb-title">Dashboard</h2>
              </div>
            </div>
          </div>
        </div>
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <Sidebar />
              <div className="col-md-7 col-lg-8 col-xl-9">
                <div className="row">
                  <div className="col-md-12 col-lg-4 dash-board-list blue">
                    <div className="dash-widget">
                      <div className="circle-bar">
                        <div className="icon-col">
                          <FontAwesomeIcon icon={faFish} />
                        </div>
                      </div>
                      <div className="dash-widget-info">
                        <h3>{count.count
                        }</h3>
                        <h6>fish</h6>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 col-lg-4 dash-board-list yellow">
                    <div className="dash-widget">
                      <div className="circle-bar">
                        <div className="icon-col">
                          <i className="fas fa-calendar-check" />
                        </div>
                      </div>
                      <div className="dash-widget-info">
                        <h3>
                          {orders && orders.data ? orders.data.length : "0"}
                        </h3>
                        <h6>Order</h6>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 col-lg-4 dash-board-list pink">
                    <div className="dash-widget">
                      <div className="circle-bar">
                        <div className="icon-col">
                          <i className="fas fa-users" />
                        </div>
                      </div>
                      <div className="dash-widget-info">
                        <h3>{users && users.data ? users.data.length : "0"}</h3>

                        <h6> Users</h6>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <h4 className="mb-4">
                      list of commands or liste des commandes
                    </h4>
                    <div className="card card-table">
                      <div className="card-body">
                        <div className="table-responsive">
                          <table className="table table-hover table-center mb-0">
                            <thead>
                              <tr>
                                <th>ID</th>
                                <th>User</th>
                                <th className="text-center">Date</th>
                                <th className="text-center">ACTION</th>
                              </tr>
                            </thead>
                            <tbody>
                              {orders &&
                                orders.data &&
                                orders.data.map((order) => (
                                  <tr>
                                    <td>{order._id}</td>
                                    <td>
                                      <h2 className="table-avatar">
                                        <a href="/">
                                          {order.user && order.user.name}
                                          <span>
                                            <span
                                              className="__cf_email__"
                                              data-cfemail="3b4f424954555e4954595e494f487b5a"
                                            >
                                              {order.user && order.user.email}
                                            </span>
                                          </span>
                                        </a>
                                      </h2>
                                    </td>
                                    <td className="text-center">
                                      {moment(order?.created_at).format(
                                        "DD-MM-YYYY hh:mm"
                                      )}
                                    </td>{" "}
                                    <td className="text-center">
                                      <div className="actions">
                                        <Link
                                          to={`/order/${order._id}`}
                                          className="btn btn-sm bg-info-light"
                                        >
                                          <i className="far fa-eye" /> View
                                        </Link>{" "}
                                        &nbsp;
                                      </div>
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </body>
  );
}

export default Index
