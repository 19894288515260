import axios from "axios";
import {
  CART_ADD_ITEM,
  CART_REMOVE_ITEM,
  CART_SAVE_SHIPPING_ADDRESS,
} from "../constants/cartConstant";

export const addTocart = (id, qty) => async (dispatch, getState) => {
  const { data } = await axios.get(`/api/fishes/${id}`);

  dispatch({
    type: CART_ADD_ITEM,
    payload: {
      fishe: data._id,
      trade_name: data.trade_name,
      image: data.image,
      size: data.size,
      weight: data.weight,
      countInStock: data.countInStock,
      qty,
    },
  });

  localStorage.setItem("carItems", JSON.stringify(getState().cart.cartItems));
};

export const removeFromCart = (id) => (dispatch, getState) => {
  dispatch({
    type: CART_REMOVE_ITEM,
    payload: id,
  });

  localStorage.setItem("carItems", JSON.stringify(getState().cart.cartItems));
};

export const saveShippingAddres = (data) => (dispatch) => {
  dispatch({
    type: CART_SAVE_SHIPPING_ADDRESS,
    payload: data,
  });

  localStorage.setItem("shippingAddress", JSON.stringify(data));
};
