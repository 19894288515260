import React, { useState, useEffect } from "react";
import Sidebar from "../../../../components/sidebar";
import Header from "../../../../components/Header";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate,  Link } from "react-router-dom";
import moment from "moment";
import axios from "axios";
import {subscribers}from "../../../../actions/subscribsAction"
function Index() {
      const navigate = useNavigate();

  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const subs = useSelector((state) => state.sub.sub);
 // const { loading, error, sub } = subs;
    const userLogin = useSelector((state) => state.userLogin);
    const { userInfo } = userLogin;
  //subscribers/
  useEffect(() => {
    if (userInfo && userInfo.data.isAdmin) {
      dispatch(subscribers());
    } else {
      navigate("/login");
    }
  }, [dispatch, userInfo]);
  return (
    <body>
      <div className="main-wrapper">
        <Header />

        <div className="breadcrumb-bar">
          <br />
          <br />
          <br />
          <div className="container-fluid">
            <div className="row">
              <Sidebar />
              <div className="col-md-7 col-lg-8 col-xl-9">
                <div className="row">
                  <div className="col-md-12">
                    <h4 className="mb-4">{t("Subscribes")} </h4>
                    <div className="card card-table">
                      <div className="card-body">
                        <div className="table-responsive">
                          <table className="table table-hover table-center mb-0">
                            <thead>
                              <tr>
                                <th></th>
                                <th className="text-center">{t("email")}</th>
                              </tr>
                            </thead>
                            <tbody>
                              {subs &&
                                subs.map((subscribe, index) => (
                                  <tr>
                                    <td>{index + 1}</td>
                                    <td className="text-center">
                                      {subscribe.email}
                                    </td>{" "}
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </body>
  );
}

export default Index
