import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import {
  fisheListReducers,
  fisheDetailReducers,
  fisheDeleleReducers,
  fisheCreateReducers,
  fisheUpdateReducers,
  fisheReviewCreateReducers,
  fisheTopRatedReducers,
  ListfishbyTypeReducers,
  countfishReducers,
} from "./reducers/fisheReducer";

import { cartReducer } from "./reducers/cartReducer";
import { subReducer } from "./reducers/subsReducer";


import {
  userLoginReducers,
  userRegisterReducers,
  userDetailsReducers,
  userUpdateProfileReducers,
  userListReducers,
  userDeleteReducers,
  userUpdateReducers,
} from "./reducers/userReducer";
import {
  orderCreateReducer,
  orderDetailsReducer,
  orderListMyReducer,
  orderListReducer,
  orderDeleleReducers,
} from "./reducers/orderReducer";

const reducer = combineReducers({
  fisheList: fisheListReducers,
  fisheDetails: fisheDetailReducers,
  fisheDelete: fisheDeleleReducers,
  fisheCreate: fisheCreateReducers,
  fisheUpdate: fisheUpdateReducers,
  fisheReviewCreate: fisheReviewCreateReducers,
  fisheTopRated: fisheTopRatedReducers,
  fishByType: ListfishbyTypeReducers,
  countfish: countfishReducers,

  cart: cartReducer,
  orderCreate: orderCreateReducer,
  orderDetails: orderDetailsReducer,
  orderListMy: orderListMyReducer,
  orderList: orderListReducer,
  orderDelete: orderDeleleReducers,

  userLogin: userLoginReducers,
  userRegister: userRegisterReducers,
  userDetails: userDetailsReducers,
  userUpdateProfile: userUpdateProfileReducers,
  userList: userListReducers,
  userDelete: userDeleteReducers,
  userUpdate: userUpdateReducers,
  sub: subReducer,
});

const userInfoFromStorage = localStorage.getItem("userInfo")
  ? JSON.parse(localStorage.getItem("userInfo"))
  : null;

const cartItemsFromStorage = localStorage.getItem("cartItems")
  ? JSON.parse(localStorage.getItem("cartItems"))
  : [];

const initialState = {
  userLogin: { userInfo: userInfoFromStorage },
  cart: { cartItems: cartItemsFromStorage },
};

const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
