import axios from "axios";
import {
  SUBSCRIBES_REQUEST,
 SUBSCRIBES_SUCCESS ,
 SUBSCRIBES_FAIL
} from "../constants/fisheConstant";

export const subscribers = () => async (dispatch) => {
  try {
    dispatch({ type: SUBSCRIBES_REQUEST });
    const { data } = await axios.get(`/api/subscribers/`);

    dispatch({
      type: SUBSCRIBES_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: SUBSCRIBES_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};