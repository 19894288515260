import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import Header from "../../components/Header";
import emailjs from "@emailjs/browser";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Index() {
  const form = useRef();
  const { t } = useTranslation(); // Access the translation function

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service",
        "template_ee0g1f9",
        form.current,
        "EjkL6B16h2EHTCj5P"
      )
      .then(
        (result) => {
          toast.success(t("messageSent"));
          console.log(result.text);

          form.current.reset();
        },
        (error) => {
          toast.error(t("error"));
          console.log(error.text);
        }
      );
  };

  return (
    <div className="main-wrapper" style={{ backgroundColor: "#f1f7ff" }}>
      <Header />
      <ToastContainer />
      <section className="home-slide-five d-flex align-items-center">
        <div className="container">
          <div className="row">
            <div className="col-md-3 align-items-center">
              <div className="home-slide-face aos"></div>
            </div>
            <div className="col-xl-6 d-flex girl-slide-img aos">
              <div
                className="container login-right"
                style={{ maxWidth: "600px" }}
              >
                <h1>{t("contactUs")}</h1>

                <form ref={form} onSubmit={sendEmail}>
                  <div className="form-group">
                    <label className="form-control-label">
                      {t("subject")}:
                    </label>
                    <input
                      type="name"
                      name="user_object"
                      className="form-control"
                    />
                  </div>
                  <div className="form-group">
                    <label className="form-control-label">{t("name")}:</label>
                    <input
                      type="name"
                      className="form-control"
                      name="user_name"
                    />
                  </div>
                  <div className="form-group">
                    <label className="form-control-label">
                      {t("emailAddress")}
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      name="user_email"
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label className="form-control-label">{t("message")}</label>
                    <div className="pass-group">
                      <textarea
                        id="message"
                        name="message"
                        rows="4"
                        className="form-control pass-input"
                        required
                      ></textarea>
                    </div>
                  </div>
                  <button
                    className="btn btn-primary login-btn"
                    type="submit"
                    value="Envoyer"
                    style={{ background: "#296ED6" }}
                  >
                    {t("send")}
                  </button>
                </form>
              </div>
            </div>
          </div>
          <div className="slide-background">
            <img src="assets/img/slide-bg2.png" alt="" />
          </div>
        </div>
      </section>
    </div>
  );
}

export default Index;
