import {
  ORDER_CREATE_REQUEST,
  ORDER_CREATE_SUCCESS,
  ORDER_CREATE_FAIL,
  ORDER_CREATE_RESET,
  ORDER_DETAILS_REQUEST,
  ORDER_DETAILS_SUCCESS,
  ORDER_DETAILS_FAIL,
  MYORDER_LIST_REQUEST,
  MYORDER_LIST_SUCCESS,
  MYORDER_LIST_FAIL,
  MYORDER_LIST_RESET,
  ORDER_LIST_REQUEST,
  ORDER_LIST_SUCCESS,
  ORDER_LIST_FAIL,
  ORDER_DELETE_REQUEST,
  ORDER_DELETE_SUCCESS,
  ORDER_DELETE_FAIL,
} from "../constants/orderConstant";
import axios from 'axios'
import {CART_CLEAR_ITEMS} from '../constants/cartConstant'

export const createOrder =(order)=> async (dispatch,getState)=>{
    try{
        dispatch({
            type:ORDER_CREATE_REQUEST
        })

  const {
     userLogin:{userInfo},

  } = getState()

        const config = {
            headers:{
                'Content-type':'application/json',
                 Authorization : `Bearer ${userInfo.data.token}`
            }
        }
        const data = await axios.post(
            `/api/orders/add/`,
            order,
            config
        )
        dispatch({
            type:ORDER_CREATE_SUCCESS,
            payload:data
        })

        dispatch({
            type:CART_CLEAR_ITEMS,
            payload:data
        })

        localStorage.removeItem('cartItems')

    }catch(error){
        dispatch({
            type:ORDER_CREATE_FAIL,
            payload:error.response && error.response.data.detail 
            ?error.response.data.detail
            :error.message,
        })
    }

}

export const getOrderDetails =(id)=> async (dispatch,getState)=>{
    try{
        dispatch({
            type:ORDER_DETAILS_REQUEST
        })

  const {
     userLogin:{userInfo},

  } = getState()

        const config = {
            headers:{
                'Content-type':'application/json',
                 Authorization : `Bearer ${userInfo.data.token}`
            }
        }
        const data = await axios.get(
            `/api/orders/${id}/`,
            config
        )
        dispatch({
            type:ORDER_DETAILS_SUCCESS,
            payload:data
        })


    }catch(error){
        dispatch({
            type:ORDER_DETAILS_FAIL,
            payload:error.response && error.response.data.detail 
            ?error.response.data.detail
            :error.message,
        })
    }

}





export const listMyOrders =()=> async (dispatch,getState)=>{
    try{
        dispatch({
            type:MYORDER_LIST_REQUEST
        })

  const {
     userLogin:{userInfo},

  } = getState()

        const config = {
            headers:{
                'Content-type':'application/json',
                 Authorization : `Bearer ${userInfo.data.token}`
            }
        }
        const data = await axios.get(
            `/api/orders/myorders/`,
            config
        )
        dispatch({
            type:MYORDER_LIST_SUCCESS,
            payload:data
        })


    }catch(error){
        dispatch({
            type:MYORDER_LIST_FAIL,
            payload:error.response && error.response.data.detail 
            ?error.response.data.detail
            :error.message,
        })
    }

}



export const listOrders = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: ORDER_LIST_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.data.token}`,
      },
    };
    const data = await axios.get(`/api/orders/`, config);
    dispatch({
      type: ORDER_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ORDER_LIST_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const deleteOrder = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ORDER_DELETE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.data.token}`,
      },
    };

    const data = await axios.delete(`/api/orders/delete/${id}/`, config);
    dispatch({
      type: ORDER_DELETE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ORDER_DELETE_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

