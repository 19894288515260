import React, { useContext } from "react";
import { Link } from "react-router-dom";
import Rating from "./Rating";
import { LanguageContext } from "../TradeNameLangague/LanguageContext";

function Index({ fishe }) {
  const { selectedLanguage } = useContext(LanguageContext);

  let tradeName;
  if (selectedLanguage === "fr" && fishe.trade_name_fr) {
    tradeName = fishe.trade_name_fr;
  } else if (selectedLanguage === "es" && fishe.trade_name_es) {
    tradeName = fishe.trade_name_es;
  } else {
    tradeName = fishe.trade_name;
  }
  return (
    <div class="profile-widget">
      <div class="user-avatar">
        <Link to={`/products/${fishe._id}`}>
          <img
            class="img-fluid"
            style={{ height: "300px", objectFit: "cover" }}
            alt="fish"
            src={fishe.image}
          />
        </Link>
        <a href="javascript:void(0)"></a>
      </div>
      <div class="pro-content">
        <h3 class="title">
          <Link to={`/products/${fishe._id}`} class='a'>
            {tradeName}
          </Link>
        </h3>
        <p class="speciality">{fishe.category}</p>
        <div class="rating">
          <div>
            <Rating
              value={fishe.rating}
              text={`${fishe.numReviews} reviews`}
              color={"#FFD700"}
            />
          </div>
        </div>
        <ul class="available-info">
          <li>classe : {fishe.classe}</li>
       
        </ul>
      </div>
     
    </div>
  );
}

export default Index;
