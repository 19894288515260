import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Header from "../../components/Header/index";
import Footer from "../../components/Footer/index";
import { useDispatch, useSelector } from "react-redux";
import P404 from "../p404";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { saveShippingAddres } from "../../actions/cartAction";

function Index() {
  const { t } = useTranslation();
  const cart = useSelector((state) => state.cart);
  const { shippingAddress } = cart;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [phone, setPhone] = useState();
  const [address, setAddress] = useState();
  const [city, setCity] = useState();
  const [postalCode, setPostalCode] = useState();
  const [country, setCountry] = useState();

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(saveShippingAddres({ phone, address, city, postalCode, country }));
    navigate("/placeorder");
  };

  return (
    <div
      className="main-wrapper"
      style={{ backgroundColor: "#f1f7ff", minHeight: "700px" }}
    >
      <Header />
      <div className="content">
        <div className="container">
          <div className="row">
            <div className="card">
              <div className="card-body">
                <form onSubmit={submitHandler}>
                  <div className="info-widget">
                    <h4 className="card-title">{t("Personal Information")}</h4>
                    <div className="row">
                      <div className="col-md-6 col-sm-12">
                        <div className="form-group card-label">
                          <label htmlFor="cvv">{t("Phone Number")}</label>
                          <input
                            className="form-control"
                            required
                            value={phone ? phone : ""}
                            onChange={(e) => setPhone(e.target.value)}
                            type="text"
                          />
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-12">
                        <div className="form-group card-label">
                          <label>{t("Address")}</label>
                          <input
                            className="form-control"
                            type="text"
                            required
                            value={address ? address : ""}
                            onChange={(e) => setAddress(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group card-label">
                          <label htmlFor="expiry_month">{t("Country")}</label>
                          <input
                            className="form-control"
                            type="text"
                            required
                            value={country ? country : ""}
                            onChange={(e) => setCountry(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group card-label">
                          <label htmlFor="expiry_year">{t("City")}</label>
                          <input
                            className="form-control"
                            required
                            value={city ? city : ""}
                            onChange={(e) => setCity(e.target.value)}
                            type="text"
                          />
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-12">
                        <div className="form-group card-label">
                          <label>{t("Postal Code")}</label>
                          <input
                            className="form-control"
                            type="text"
                            required
                            value={postalCode ? postalCode : ""}
                            onChange={(e) => setPostalCode(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    {/*
                    <div className="exist-customer">
                      Existing Customer? <a href="#">Click here to login</a>
                    </div>
                     */}
                    <div class="submit-section proceed-btn text-end">
                      <button
                        type="submit"
                        class="btn btn-primary submit-btn"
                        variant="primary"
                      >
                        {t("Continue")}
                      </button>
                    </div>
                  </div>
                </form>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Index;
