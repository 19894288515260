import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { LanguageContext } from "../TradeNameLangague/LanguageContext";

function Fish({ fishe }) {
  console.log(fishe);

  const { selectedLanguage } = useContext(LanguageContext);

  let tradeName;
  if (selectedLanguage === "fr" && fishe.trade_name_fr) {
    tradeName = fishe.trade_name_fr;
  } else if (selectedLanguage === "es" && fishe.trade_name_es) {
    tradeName = fishe.trade_name_es;
  } else {
    tradeName = fishe.trade_name;
  }

  return (
    <div className="large-team">
      <div className="large-team-space">
        <Link to={`/products/${fishe._id}`}></Link>
        <div className="student-img">
          <Link to={`/products/${fishe._id}`}>
            <img
              src={fishe.image}
              style={{ height: "300px", objectFit: "cover" }} // Set width and height using CSS
              alt
            />
          </Link>
          <div className="blog-read align-items-center d-flex justify-content-center">
            <Link to={`/products/${fishe._id}`}>Read More..</Link>
          </div>
          <div className="rating-student-five">
            <div className="student-list-view">
              <h4>{tradeName}</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Fish;
