import React from "react";
import { useDispatch } from "react-redux";

const Pagination = ({ pages, page, handlePageChange }) => {
 // const dispatch = useDispatch();
  const o = (pageNumber, np) => {
  //  dispatch(listFishes("", pageNumber, np)); // Update the keyword and page number, and fetch data
  };
  return (
    <>
      {pages > 1 && (
        <nav>
          <ul className="pagination">
            {[...Array(pages).keys()].map((pageNumber) => (
              <li
                key={pageNumber}
                className={`page-item ${
                  pageNumber + 1 === page ? "active" : ""
                }`}
              >
                <button
                  className="page-link"
                  onClick={() => handlePageChange(pageNumber + 1)}
                >
                  {pageNumber + 1}
                </button>
              </li>
            ))}
          </ul>
        </nav>
      )}
    </>
  );
};

export default Pagination;
