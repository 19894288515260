import React, { useState, useEffect } from "react";
import Sidebar from "../../../../components/sidebar";
import Header from "../../../../components/Header";
import { useDispatch, useSelector } from "react-redux";
import { listMyOrders, getOrderDetails } from "../../../../actions/orderAction";
import { useNavigate, useLocation ,Link} from "react-router-dom";
import { useTranslation } from "react-i18next"; // Importing useTranslation hook
import moment from "moment";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCreditCard,
  faShrimp,
  faFish,
  faMoneyBillTransfer,
} from "@fortawesome/free-solid-svg-icons";

function Index() {
  const { t } = useTranslation(); // Initializing the useTranslation hook

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const orderListMy = useSelector((state) => state.orderListMy);
  const { loading: loadingOrders, error: errorOrders, orders } = orderListMy;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    if (userInfo && userInfo.data) {
      dispatch(listMyOrders());
    } else {
      navigate("/login");
    }
  }, [dispatch, navigate, userInfo]);

  const getbyId = (id) => {
    console.log(id);
    dispatch(getOrderDetails(id));
  };
  const orderDetails = useSelector((state) => state.orderDetails);
  const { order } = orderDetails;

  return (
    <body>
      <div className="main-wrapper">
        <Header />

        <div className="breadcrumb-bar">
          <br />
          <br />
          <br />
          <div className="container-fluid">
            <div className="row">
              <Sidebar />
              <div className="col-md-7 col-lg-8 col-xl-9">
                <div className="row">
                  <div className="col-md-12">
                    <h4 className="mb-4">{t("list of orders")} </h4>
                    <div className="card card-table">
                      <div className="card-body">
                        <div className="table-responsive">
                          <table className="table table-hover table-center mb-0">
                            <thead>
                              <tr>
                                <th></th>
                                <th className="text-center">{t("Date")}</th>
                                <th className="text-center">{t("ACTION")}</th>
                              </tr>
                            </thead>
                            <tbody>
                              {orders &&
                                orders.data &&
                                orders.data.map((order, index) => (
                                  <tr>
                                    <td>{index + 1}</td>
                                    <td className="text-center">
                                      {moment(order?.created_at).format(
                                        "DD-MM-YYYY hh:mm"
                                      )}
                                    </td>{" "}
                                    <td className="text-center">
                                      <div className="actions">
                                        <Link
                                          to={`/order/${order._id}`}
                                          className="btn btn-sm bg-info-light"
                                        >
                                          <i className="far fa-eye" /> View
                                        </Link>{" "}
                                        &nbsp;
                                      </div>
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </body>
  );
}

export default Index;
