import React from "react";
import ReactWhatsapp from "react-whatsapp";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function Index() {
      const { t, i18n } = useTranslation();

  return (
    <div className="main-wrapper">
      <footer className="footer footer-five">
        <div className="footer-top aos ">
          <div className="container">
            <div className="row">
              <div className="col-lg-5 col-md-6">
                <div className="footer-widget footer-about">
                  <div className="footer-logo">
                    <img src="assets/img/logo.png" alt="logo" />
                  </div>
                  <div className="footer-about-content">
                    <p className="footer-sub-text">
                      {t(
                        "Our team is dedicated to providing excellent customer service and helping our customers select the perfect fish for their needs."
                      )}
                    </p>
                    <div className="social-icon">
                      <ul>
                        <li>
                          <ReactWhatsapp
                            number="+22243395858"
                            message="I'm, Intersted"
                            // className="btn bg-stop-learn"
                            style={{
                              color: "black",
                              border: "none",
                              borderRadius: "5px",
                              background: "F5F5DC",
                              padding: "6px 20px",
                              font: "16px",
                              fontSize: "1rem",
                              marginBottom: "5px",
                            }}
                          >
                            <i
                              className="fab fa-whatsapp"
                              style={{
                                color: "#10ae26",
                              }}
                            />
                            &nbsp;Whatsapp
                          </ReactWhatsapp>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="footer-widget footer-contact">
                  <h2 className="footer-title">Contact Us</h2>
                  <div className="footer-contact-info">
                    <div className="footer-address">
                      <span>
                        <i className="fas fa-map-marker-alt" />
                      </span>
                      <p> NDB, Mauritanie </p>
                    </div>
                    <p>
                      <i className="fas fa-phone-alt" />
                      +222 43395858
                    </p>
                    {/*
                      <p>
                      <i className="fas fa-envelope" />
                      <a href="mailto:fishefinder@gmail.com">
                        fishefinder@gmail.com
                      </a>
                    </p> */}
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="footer-widget footer-about">
                  <div>
                    <img
                      src="assets/img/footer1.png"
                      alt="logo"
                      style={{ textAlign: "center" }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom ">
          <div className="container">
            <div className="copyright-border" />
            <div className="copyright ">
              <div className="row align-items-center">
                <p style={{ textAlign: "center" }}>
                  © 2023 Atlantic Ocean Fish. {t("All rights reserved")}.
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}
export default Index;
