import {
  SUBSCRIBES_REQUEST,
  SUBSCRIBES_SUCCESS,
  SUBSCRIBES_FAIL,
} from "../constants/fisheConstant";


export const subReducer = (state = { sub: [] }, action) => {
  switch (action.type) {
    case SUBSCRIBES_REQUEST:
      return { loading: true, sub: [] };
    case SUBSCRIBES_SUCCESS:
      return { loading: false, sub: action.payload };
    case SUBSCRIBES_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};