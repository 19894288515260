import axios from "axios";
import {
  FISHE_LIST_REQUEST,
  FISHE_LIST_SUCCESS,
  FISHE_LIST_FAIL,
  FISHE_DETAILS_REQUEST,
  FISHE_DETAILS_SUCCESS,
  FISHE_DETAILS_FAIL,
  FISHE_DELETE_REQUEST,
  FISHE_DELETE_SUCCESS,
  FISHE_DELETE_FAIL,
  FISHE_CREATE_REQUEST,
  FISHE_CREATE_SUCCESS,
  FISHE_CREATE_FAIL,
  FISHE_CREATE_RESET,
  FISHE_CREATE_REVIEW_REQUEST,
  FISHE_CREATE_REVIEW_SUCCESS,
  FISHE_CREATE_REVIEW_FAIL,
  FISHE_CREATE_REVIEW_RESET,
  FISHE_UPDATE_REQUEST,
  FISHE_UPDATE_SUCCESS,
  FISHE_UPDATE_FAIL,
  FISHE_TOP_REQUEST,
  FISHE_TOP_SUCCESS,
  FISHE_TOP_FAIL,
  FISHE_TOP_RESET,
  Fish_GET_BY_TYPE_REQUEST,
  Fish_GET_BY_TYPE_SUCCESS,
  Fish_GET_BY_TYPE_FAIL,
  Fish_GET_BY_TYPE_RESET,
  COUNT_FISH_REQUEST,
  COUNT_FISH_SUCCESS,
  COUNT_FISH_FAIL,
} from "../constants/fisheConstant";


export const listFishes = (keyword, page, perPage) => async (dispatch) => {
  try {
    dispatch({ type: 'FISHE_LIST_REQUEST' });

    const { data } = await axios.get("/api/fish/", {
      params: { keyword, page, per_page: perPage },
    });

    dispatch({
      type: 'FISHE_LIST_SUCCESS',
      payload: data,
    });
  } catch (error) {  
    dispatch({
      type: 'FISHE_LIST_FAIL',
      payload: error.response && error.response.data.detail
        ? error.response.data.detail
        : error.message,
    });
  }
};

export const listTopFishes = () => async (dispatch) => {
  try {
    dispatch({ type: FISHE_TOP_REQUEST });
    const { data } = await axios.get(`/api/fishes/top/`);

    dispatch({
      type: FISHE_TOP_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: FISHE_TOP_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const listFishesDetails = (id) => async (dispatch) => {
  try {
    dispatch({ type: FISHE_DETAILS_REQUEST });
    const { data } = await axios.get(`/api/fishes/${id}`);

    dispatch({
      type: FISHE_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: FISHE_DETAILS_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const deleteFishe = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: FISHE_DELETE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.data.token}`,
      },
    };

    const data = await axios.delete(`/api/fishes/delete/${id}/`, config);
    dispatch({
      type: FISHE_DELETE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: FISHE_DELETE_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};


export const createFishe = (formData) => async (dispatch, getState) => {
  try {
    dispatch({
      type: FISHE_CREATE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "multipart/form-data",
        Authorization: `Bearer ${userInfo.data.token}`,
      },
    };

    const data = await axios.post("/api/fishes/create/", formData, config);

    dispatch({
      type: FISHE_CREATE_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    dispatch({
      type: FISHE_CREATE_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const updateFishe = (fishe) => async (dispatch, getState) => {
  try {
    dispatch({
      type: FISHE_UPDATE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.data.token}`,
      },
    };

    const data = await axios.put(
      `/api/fishes/update/${fishe._id}/`,
      fishe,
      config
    );
console.log(fishe);
    dispatch({
      type: FISHE_UPDATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: FISHE_UPDATE_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};
export const updateFish = (fishe) => async (dispatch, getState) => {
console.log(fishe);
  try {console.log(fishe);
    dispatch({
      type: FISHE_UPDATE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.data.token}`,
      },
    };

    const data = await axios.put(
      `/api/fishes/update/${fishe._id}/`,
      fishe,
      config
    );

    dispatch({
      type: FISHE_UPDATE_SUCCESS,
      payload: data,
    });

  } catch (error) {
    dispatch({
      type: FISHE_UPDATE_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const createFisheReview = (id, review) => async (dispatch, getState) => {
  try {
    dispatch({
      type: FISHE_CREATE_REVIEW_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.data.token}`,
      },
    };

    const data = await axios.post(`/api/fishes/${id}/reviews/`, review, config);

    dispatch({
      type: FISHE_CREATE_REVIEW_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: FISHE_CREATE_REVIEW_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const listFishbyType = (typeFish) => async (dispatch) => {
  try {
    dispatch({ type: Fish_GET_BY_TYPE_REQUEST });
    const { data } = await axios.get(`/api/fishes/type/${typeFish}`);

    dispatch({
      type: Fish_GET_BY_TYPE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: Fish_GET_BY_TYPE_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const countFish = () => async (dispatch) => {
  try {
     dispatch({ type: COUNT_FISH_REQUEST });
    const { data } = await axios.get(`/api/fish/count/`);

    dispatch({
      type: COUNT_FISH_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: COUNT_FISH_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};
