import React from "react";
import { useTranslation } from "react-i18next";
import "./index.css";
import Header from "../../components/Header/index";
import Footer from "../../components/Footer/index";


function About() {
  const { t } = useTranslation();

  return (
    <div className="main-wrapper" style={{ background: "#F1F7FF" }}>
      <Header />
      <div className="about-page d-flex align-items-center">
        <div className="container girl-slide-img ">
          <div className="row">
            <div className="col-lg-12 ">
              <div className="section-header section-head-left aos ">
                <h2>{t("About")}</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <h2 className="section-title">{t("Description-text")}</h2>

              <h2 className="section-title">{t("Our-mission")}</h2>

              <h2 className="section-title">{t("Our-values")}</h2>
            </div>
            <div className="col-lg-6">
              <img
                src="assets/img/about1.jpeg"
                alt="About us"
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="container about">
        <br />
        <div className="align-items-center">
          <div className="row">
            <div className="col-lg-3">
              <img
                src="assets/img/about2.jpeg"
                alt="About us"
                className="img-fluid"
              />
            </div>
            <div className="col-lg-3">
              <img
                src="assets/img/about3.jpeg"
                alt="About us"
                className="img-fluid"
              />
            </div>
            <div className="col-lg-3">
              <img
                src="assets/img/about4.jpeg"
                alt="About us"
                className="img-fluid"
              />
            </div>
            <div className="col-lg-3">
              <img
                src="assets/img/about5.jpeg"
                alt="About us"
                className="img-fluid"
              />
            </div>
          </div>{" "}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default About;
