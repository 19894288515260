import React from 'react'
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamation } from "@fortawesome/free-solid-svg-icons";
function Index() {
  return (
    <body class="error-page" style={{ marginTop: "120px" }}>
      <div class="main-wrapper">
        <div class="error-box">
          <h1>404</h1>
          <h3 class="h2 mb-3">
            Oops <FontAwesomeIcon icon={faExclamation} />
            &nbsp; Page not found <FontAwesomeIcon icon={faExclamation} />
          </h3>

          <p class="h4 font-weight-normal">
            The page you requested was not found.
          </p>
          <Link to="/" class="btn btn-primary">
            Back to Home
          </Link>
        </div>
      </div>

      {/* <div class="main-wrapper">
        <div class="error-box">
          <h1>404</h1>
          <h3 class="h2 mb-3">
            <i class="fa fa-warning"></i> Oups, Page introuvable!
          </h3>
          <p class="h4 font-weight-normal">
            Nous n'arrivons pas à trouver la page que vous recherchez.
          </p>
          <Link to="/" class="btn btn-primary">
            Retour à la page d'accueil
          </Link>
        </div>
      </div> */}
    </body>
  );
}

export default Index

