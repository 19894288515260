import React, { useState, useEffect, useContext } from "react";
import Header from "../../components/Header";
import { Link, useParams, useNavigate } from "react-router-dom";
import Rating from "../../components/Fish/Rating";
import Footer from "../../components/Footer"
import P404 from "../p404/index";
import { useDispatch, useSelector } from "react-redux";
import {
  listFishesDetails,
  createFisheReview,
} from "../../actions/fisheActions";
import { FISHE_CREATE_REVIEW_RESET } from "../../constants/fisheConstant";
import { LanguageContext } from "../../components/TradeNameLangague/LanguageContext";
import { useTranslation } from "react-i18next";

function Index() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [qty, setQty] = useState(1);
  const [rating, setRating] = useState(0);
  const [note, setNote] = useState(0);
  const [comment, setComment] = useState("");

  const { t } = useTranslation(); // Access the translation function

  const fisheDetails = useSelector((state) => state.fisheDetails);
  const { loading, error, fishe } = fisheDetails;
  const fisheReviewCreate = useSelector((state) => state.fisheReviewCreate);
  const {
    loading: loadingFisheReview,
    error: errorFisheReview,
    success: successFisheReview,
  } = fisheReviewCreate;

  const { selectedLanguage } = useContext(LanguageContext);

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const addToCartHandler = () => {
    navigate(`/cart/${id}?qty=${qty}`);
  };
  useEffect(() => {
    if (successFisheReview) {
      setRating(0);
      setComment("");
      dispatch({ type: FISHE_CREATE_REVIEW_RESET });
    }

    dispatch(listFishesDetails(id));
  }, [dispatch, successFisheReview]);

  let tradeName;
  if (selectedLanguage === "fr" && fishe.trade_name_fr) {
    tradeName = fishe.trade_name_fr;
  } else if (selectedLanguage === "es" && fishe.trade_name_es) {
    tradeName = fishe.trade_name_es;
  } else {
    tradeName = fishe.trade_name;
  }

  const submitHandler = (e) => {
    e.preventDefault();
    console.log(id, rating, comment);
    dispatch(
      createFisheReview(id, {
        rating,
        comment,
      })
    );
  };
  return (
    <div>
      {error ? (
        <P404 />
      ) : (
        <div className="main-wrapper" style={{ backgroundColor: "#f1f7ff" }}>
          <Header />
          <br />

          <div className="content">
            <div
              className="container"
              style={{ backgroundColor: "#fff", borderRadius: "10px" }}
            >
              <div className="row">
                <div className="col-lg-7 col-md-12">
                  <div className="blog-view">
                    <div className="blog blog-single-post">
                      <div className="blog-image">
                        <a href="javascript:void(0);">
                          <img
                            style={{
                              maxHeight: "400px",
                              //  height: "2px",
                              //  marginRight: "10px",
                            }}
                            src={fishe.image}
                            alt={fishe.trade_name}
                            className="img-fluid"
                          />
                        </a>
                      </div>
                    </div>
                  </div>{" "}
                </div>
                <div className="col-lg-5 col-md-12 theiaStickySidebar">
                  <div>
                    <div className="card-body">
                      <h2>{tradeName}</h2>
                      <ul className="latest-posts">
                        <li>
                          <Rating
                            value={fishe.rating}
                            text={`${fishe.numReviews} ${t("reviews")}`}
                            color={"#f8e825"}
                          />
                        </li>
                        <hr />
                        <li>
                          <div className="post-thumb">{t("class")}:</div>
                          <div className="post-info">
                            <h4> {fishe.classe}</h4>
                          </div>
                        </li>
                        <hr />
                        <li>
                          <div className="post-thumb">{t("family")}:</div>
                          <div className="post-info">
                            <h4>{fishe.category}</h4>
                          </div>
                        </li>
                        <hr />
                        <li>
                          <div className="post-thumb">
                            {t("quantity in ton")}:
                          </div>
                          <div className="post-info">
                            <div className="row">
                              <div className="col-4">
                                {" "}
                                <input
                                  id="number"
                                  type="number"
                                  required
                                  placeholder="1"
                                  className="form-control"
                                  style={{
                                    width: "90px",
                                    //  height: "2px",
                                    //  marginRight: "10px",
                                  }}
                                  value={qty}
                                  onChange={(e) => setQty(e.target.value)}
                                />
                              </div>
                              {/*
                               <div className="col-4">
                                <b
                                  className="T"
                                  style={{
                                    margin: "20px",
                                  }}
                                >
                                  T
                                </b>
                              </div>
                               */}
                            </div>
                          </div>
                        </li>{" "}
                        <hr />
                        <li>
                          <div className="post-thumb">{t("status")}:</div>
                          <div className="post-info">
                            <h4>
                              {fishe.countInStock > 0
                                ? t("inStock")
                                : t("outOfStock")}
                            </h4>
                          </div>
                        </li>
                        <li>
                          {userInfo != undefined ? (
                            userInfo.data.isAdmin == false ? (
                              fishe.countInStock > 0 ? (
                                <buttom
                                  className="btn btn-primary btn-block w-100"
                                  style={{ background: "#296ED6" }}
                                  onClick={addToCartHandler}
                                >
                                  {t("makeOrder")}
                                </buttom>
                              ) : (
                                <buttom
                                  className="btn btn-primary btn-block w-100 disabled"
                                  style={{ background: "#296ED6" }}
                                  // onClick={addToCartHandler}
                                >
                                  {t("makeOrder")}
                                </buttom>
                              )
                            ) : (
                              " "
                            )
                          ) : (
                            <div class="submit-section">
                              <Link to="/login" style={{ color: "#ffff" }}>
                                <buttom
                                  className={
                                    fishe.countInStock > 0
                                      ? "btn btn-primary btn-block w-100 disabled"
                                      : "btn btn-primary btn-block w-100"
                                  }
                                  style={{ background: "#296ED6" }}
                                  //  onClick={addToCartHandler}
                                  //    disabled={fishe.countInStock == 0}
                                >
                                  {t("makeOrder")}
                                </buttom>
                              </Link>
                            </div>
                          )}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>{" "}
              </div>
              <br />
              <div className="row">
                <div class="col-lg-8 col-md-12">
                  {fishe.reviews.length == 0 && <> </>}
                  <div className="card blog-comments clearfix">
                    <div className="card-header">
                      <h4 className="card-title">{t("reviews")}</h4>
                    </div>
                    <div className="card-body pb-0">
                      <ul className="comments-list">
                        {fishe.reviews.map((review) => (
                          <li>
                            <div className="comment">
                              <div className="row">
                                {" "}
                                <div class="col-lg-6 col-md-12">
                                  <h4 class="title">{review.name}</h4>
                                </div>
                                <div class="col-lg-6 col-md-12">
                                  {" "}
                                  <Rating
                                    value={review.rating}
                                    color={"#f8e825"}
                                  />
                                </div>
                              </div>
                              <div className="comment-block">
                                <p>{review.comment}</p>
                              </div>
                            </div>
                            <br />
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                  {successFisheReview && (
                    <div variant="success">{t("reviewSubmitted")}</div>
                  )}
                  <div className="card new-comment clearfix">
                    <div className="card-header">
                      <h4 className="card-title">{t("writeReview")}</h4>
                    </div>
                    <div className="card-body">
                      <div
                        class={errorFisheReview ? "alert alert-danger" : " "}
                      >
                        {errorFisheReview}
                      </div>
                      {userInfo ? (
                        <form onSubmit={submitHandler}>
                          <div className="form-group">
                            <label>
                              {t("rating")}
                              <span className="text-danger">*</span>
                            </label>
                            <select
                              class="form-control select"
                              name="subcategory"
                              value={rating}
                              onChange={(e) => {
                                setRating(e.target.value);
                              }}
                              SelectProps={{
                                native: true,
                              }}
                            >
                              <option value="">Select...</option>
                              <option value="1">{t("poor")}</option>
                              <option value="2">{t("fair")}</option>
                              <option value="3">{t("good")}</option>
                              <option value="4">{t("veryGood")}</option>
                              <option value="5">{t("excellent")}</option>
                            </select>
                          </div>
                          <div className="form-group">
                            <label>{t("comments")}</label>
                            <textarea
                              rows={4}
                              className="form-control"
                              value={comment}
                              onChange={(e) => setComment(e.target.value)}
                            />
                          </div>
                          <div className="submit-section">
                            <button
                              disabled={loadingFisheReview}
                              className="btn btn-primary submit-btn"
                              type="submit"
                            >
                              {t("submit")}
                            </button>
                          </div>
                        </form>
                      ) : (
                        <div variant="info">
                          {t("pleaseLogin")}{" "}
                          <Link className="a" to="/login">
                            {t("login")}
                          </Link>{" "}
                          {t("toWriteReview")}
                        </div>
                      )}
                    </div>
                  </div>
                </div>{" "}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Index;
