import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../components/Header/index";
import Footer from "../../components/Footer/index";
import { useLocation, useParams } from "react-router-dom";
import { listFishes, listFishbyType } from "../../actions/fisheActions";
import Card from "../../components/Fish/index";
import Pagination from "../../components/Fish/Paginate";
import P404 from "../p404";

function Index() {
  const location = useLocation();
  const dispatch = useDispatch();

  const { loading, fishes, page, pages, error } = useSelector(
    (state) => state.fisheList
  );
  
  const { fish,message } = useSelector((state) => state.fishByType);
 // const categorie = location.state?.categorie;
  const search = location.state?.search;
  const [libelle, setLibelle] = useState("");
  const { categorie } = useParams();
  console.log(categorie);
  useEffect(() => {
    setLibelle(search);
  }, [search]);

  useEffect(() => {
    categorie
      ? dispatch(listFishbyType(categorie))
      : dispatch(listFishes("", 1, 6));;
  }, [dispatch]);

  
  const handlePageChange = (pageNumber) => {
    dispatch(listFishes("", pageNumber, 6)); // Update the keyword and page number, and fetch data
  };
  return (
    <>
      {error ? (
        <P404 />
      ) : (
        <div className="main-wrapper">
          <Header />
          <br />
          <br />
          {message == "success" ? (
            <div style={{ backgroundColor: "#f1f7ff", minHeight: "700px" }}>
              {" "}
              {fish.length > 0 ? (
                <div class="content">
                  <div class="container">
                    <div class="row">
                      <div class="col-xl-12 col-lg-12 order-md-last order-sm-last order-last map-left">
                        <div class="row">
                          {fish.map((fishe) => (
                            <div
                              className="col-sm-6 col-md-4 col-xl-4"
                              key={fishe._id}
                              sm={12}
                              md={6}
                              lg={4}
                              xl={3}
                            >
                              <Card fishe={fishe} />
                            </div>
                          ))}{" "}
                        </div>
                        <div class="load-more text-center"></div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  style={{
                    marginLeft: "40px",
                    marginBottom: "180px",
                    content: "center",
                  }}
                >
                  {" "}
                  Temporarily we don't have this kind of fish
                </div>
              )}{" "}
              <Footer />
            </div>
          ) : (
            <></>
          )}

          
        </div>
      )}
    </>
  );
}

export default Index;
