import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { Link } from "react-router-dom";
import { logout } from "../../actions/userActions";
import {
  faCreditCard,
  faDollarSign,
  faMoneyBillTransfer,
} from "@fortawesome/free-solid-svg-icons";

function Index() {
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
    const { t, i18n } = useTranslation();

  const dispatch = useDispatch();
  const logoutHandler = () => {
    dispatch(logout());
  };
  return (
    <>
      {userInfo ? (
        <>
          {" "}
          {userInfo && userInfo.data.isAdmin == true ? (
            <div className="col-md-5 col-lg-4 col-xl-3 theiaStickySidebar">
              <div className="profile-sidebar">
                <div className="user-widget">
                  <div className="pro-avatar">A</div>
                  <div className="user-info-cont">
                    <h4 className="usr-name">Administrateur</h4>
                    <p className="mentor-type">{t("Welcome Back")}</p>
                  </div>
                </div>
                <div className="progress-bar-custom">
                  <div className="progress progress-xs">
                    <div
                      className="progress-bar bg"
                      role="progressbar"
                      style={{ width: "100%" }}
                      aria-valuenow={100}
                      aria-valuemin={0}
                      aria-valuemax={100}
                    />
                  </div>
                </div>
                <div className="custom-sidebar-nav">
                  <ul>
                    <li>
                      <a href="#/dashboard" className="">
                        <i className="fas fa-home" />
                        Dashboard{" "}
                        <span>
                          <i className="fas fa-chevron-right" />
                        </span>
                      </a>
                    </li>
                    <li>
                      <a href="#/admin/products">
                        <i className="fas fa-fish" />
                        Products{" "}
                        <span>
                          <i className="fas fa-chevron-right" />
                        </span>
                      </a>
                    </li>
                    <li>
                      <a href="#/users">
                        <i className="fas fa-users" />
                        Users{" "}
                        <span>
                          <i className="fas fa-chevron-right" />
                        </span>
                      </a>
                    </li>
                    <li>
                      <a href="#/admin/order">
                        <i className="fas fa-calendar-check" />
                        {t("orders")}
                        <span>
                          <i className="fas fa-chevron-right" />
                        </span>
                      </a>
                    </li>
                    <li>
                      <a href="#/profile">
                        <i className="fas fa-user-cog" />
                        {t("profile")}
                        <span>
                          <i className="fas fa-chevron-right" />
                        </span>
                      </a>
                    </li>
                    <li>
                      <a href="#/subscribes" className="">
                        <i className="fas fa-bell" />
                        subscribes{" "}
                        <span>
                          <i className="fas fa-chevron-right" />
                        </span>
                      </a>
                    </li>
                    <li>
                      <a href="#/login">
                        <i className="fas fa-sign-out-alt" />
                        {t("Logout")}
                        <span>
                          <i className="fas fa-chevron-right" />
                        </span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          ) : userInfo.data.isAdmin == false ? (
            <div className="col-md-5 col-lg-4 col-xl-3 theiaStickySidebar">
              <div className="profile-sidebar">
                <div className="user-widget">
                  <div className="pro-avatar">C</div>
                  <div className="user-info-cont">
                    <h4 className="usr-name">{userInfo.data.name}</h4>
                    <p className="mentor-type">{t("Welcome Back")}</p>
                  </div>
                </div>
                <div className="progress-bar-custom">
                  <div className="progress progress-xs">
                    <div
                      className="progress-bar bg"
                      role="progressbar"
                      style={{ width: "100%" }}
                      aria-valuenow={100}
                      aria-valuemin={0}
                      aria-valuemax={100}
                    />
                  </div>
                </div>
                <div className="custom-sidebar-nav">
                  <ul>
                    <li>
                      <a href="#/order">
                        <i className="fas fa-calendar-check" />
                        {t("orders")}
                        <span>
                          <i className="fas fa-chevron-right" />
                        </span>
                      </a>
                    </li>
                    <li>
                      <a href="#/profile">
                        <i className="fas fa-user-cog" />
                        {t("profile")}
                        <span>
                          <i className="fas fa-chevron-right" />
                        </span>
                      </a>
                    </li>
                    <li>
                      <a
                        href="#/login"
                        onClick={logoutHandler}
                        style={{
                          color: "#e72d46",
                        }}
                      >
                        <i className="fas fa-sign-out-alt" />
                        {t("Logout")}
                        <span>
                          <i className="fas fa-chevron-right" />
                        </span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </>
      ) : (
        " "
      )}
    </>
  );
}

export default Index;
