export const FISHE_LIST_REQUEST = 'FISHE_LIST_REQUEST'
export const FISHE_LIST_SUCCESS = 'FISHE_LIST_SUCCESS'
export const FISHE_LIST_FAIL = 'FISHE_LIST_FAIL'


export const FISHE_DETAILS_REQUEST = 'FISHE_DETAILS_REQUEST'
export const FISHE_DETAILS_SUCCESS = 'FISHE_DETAILS_SUCCESS'
export const FISHE_DETAILS_FAIL = 'FISHE_DETAILS_FAIL'

export const FISHE_DELETE_REQUEST = 'FISHE_DELETE_REQUEST'
export const FISHE_DELETE_SUCCESS = 'FISHE_DELETE_SUCCESS'
export const FISHE_DELETE_FAIL = 'FISHE_DELETE_FAIL'


export const FISHE_CREATE_REQUEST = 'FISHE_CREATE_REQUEST'
export const FISHE_CREATE_SUCCESS = 'FISHE_CREATE_SUCCESS'
export const FISHE_CREATE_FAIL = 'FISHE_CREATE_FAIL'
export const FISHE_CREATE_RESET = 'FISHE_CREATE_RESET'

export const FISHE_UPDATE_REQUEST = 'FISHE_UPDATE_REQUEST'
export const FISHE_UPDATE_SUCCESS = 'FISHE_UPDATE_SUCCESS'
export const FISHE_UPDATE_FAIL = 'FISHE_UPDATE_FAIL'
export const FISHE_UPDATE_RESET = 'FISHE_UPDATE_RESET'


export const FISHE_CREATE_REVIEW_REQUEST = 'FISHE_CREATE_REVIEW_REQUEST'
export const FISHE_CREATE_REVIEW_SUCCESS = 'FISHE_CREATE_REVIEW_SUCCESS'
export const FISHE_CREATE_REVIEW_FAIL = 'FISHE_CREATE_REVIEW_FAIL'
export const FISHE_CREATE_REVIEW_RESET = 'FISHE_CREATE_REVIEW_RESET'

export const FISHE_TOP_REQUEST = 'FISHE_TOP_REQUEST'
export const FISHE_TOP_SUCCESS = 'FISHE_TOP_SUCCESS'
export const FISHE_TOP_FAIL = 'FISHE_TOP_FAIL'
export const FISHE_TOP_RESET = 'FISHE_TOP_RESET'

export const Fish_GET_BY_TYPE_REQUEST = "Fish_GET_BY_TYPE_REQUEST";
export const Fish_GET_BY_TYPE_SUCCESS = "Fish_GET_BY_TYPE_SUCCESS";
export const Fish_GET_BY_TYPE_FAIL = "Fish_GET_BY_TYPE_FAIL";
export const Fish_GET_BY_TYPE_RESET = "Fish_GET_BY_TYPE_RESET";

export const COUNT_FISH_REQUEST = "COUNT_FISH_REQUEST";
export const COUNT_FISH_SUCCESS = "COUNT_FISH_SUCCESS";
export const COUNT_FISH_FAIL = "COUNT_FISH_FAIL";


export const SUBSCRIBES_REQUEST = "SUBSCRIBES_REQUEST";
export const SUBSCRIBES_SUCCESS = "SUBSCRIBES_SUCCESS";
export const SUBSCRIBES_FAIL = "SUBSCRIBES_FAIL";
