import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { LanguageContext } from "../../TradeNameLangague/LanguageContext";
import { useDispatch, useSelector } from "react-redux";
import { listTopFishes } from "../../../actions/fisheActions";
import "./index.css";
import { Carousel, Image } from "react-bootstrap";

function FisheCarousel(props) {
  const dispatch = useDispatch();
  const fisheTopRated = useSelector((state) => state.fisheTopRated);
  const { loading, error, fishes } = fisheTopRated;

  const { selectedLanguage } = useContext(LanguageContext);

  useEffect(() => {
    dispatch(listTopFishes());
  }, [dispatch]);

  return error ? (
    <></>
  ) : (
    <Carousel pause="hover" style={{ backgroundColor: "#6ca5fc" }}>
      {fishes.map((fishe) => (
        <Carousel.Item key={fishe._id}>
          <Link to={`/products/${fishe._id}`}>
            <Image src={fishe.image} alt={fishe.trade_name} fluid />
            <Carousel.Caption className="carousel.caption">
              <h4>
                {selectedLanguage === "fr"
                  ? fishe.trade_name_fr
                  : selectedLanguage === "es"
                  ? fishe.trade_name_es
                  : fishe.trade_name}
              </h4>
            </Carousel.Caption>
          </Link>
        </Carousel.Item>
      ))}
    </Carousel>
  );
}
export default FisheCarousel;
