import React, { useState, useEffect } from "react";
import Sidebar from "../../../../components/sidebar";
import Header from "../../../../components/Header/index";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { Link, useParams } from "react-router-dom";
import {
  getUserDetails,
  register,
  updateUserProfile,
} from "../../../../actions/userActions";
import { updateUser } from "../../../../actions/userActions";
import { USER_UPDATE_PROFILE_RESET } from "../../../../constants/userConstant";

import { useTranslation } from "react-i18next";

function Index() {
  const { t } = useTranslation();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState("");

  const dispatch = useDispatch();

  const userDetails = useSelector((state) => state.userDetails);
  const { error, loading, user } = userDetails;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const userUpdateProfile = useSelector((state) => state.userUpdateProfile);
  const { success } = userUpdateProfile;

  const navigate = useNavigate();

  useEffect(() => {
    if (!userInfo) {
      navigate("/login");
    } else {
      if (
        !user.data ||
        !user.data.name ||
        success ||
        userInfo.data._id !== user.data._id
      ) {
        dispatch({ type: USER_UPDATE_PROFILE_RESET });
        dispatch(getUserDetails("profile"));
      } else {
        setName(user.data.name);
        setEmail(user.data.email);
      }
    }
  }, [dispatch, navigate, user, userInfo, success]);

  const submitHandler = (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setMessage(t("passwordMismatch"));
    } else {
      dispatch(
        updateUserProfile({
          id: user._id,
          name: name,
          email: email,
          password: password,
        })
      );

      setMessage("");
    }
  };

  return (
    <div className="main-wrapper">
      <Header />
      <br />
      <div className="breadcrumb-bar">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-md-12 col-12">
              <nav aria-label="breadcrumb" className="page-breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/" className="a">
                      {t("Home")}
                    </Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    {t("profileSettings")}
                  </li>
                </ol>
              </nav>
              <h2 className="breadcrumb-title">{t("profileSettings")}</h2>
            </div>
          </div>
        </div>
        <div className="content">
          <div className={message ? "alert alert-danger" : ""}>{message}</div>

          <div className="container-fluid">
            <div className="row">
              <Sidebar />
              <div className="col-md-7 col-lg-8 col-xl-9">
                <div className="card">
                  <div className="card-body">
                    <form onSubmit={submitHandler}>
                      <div className="row form-row">
                        <div className="col-12 col-md-12">
                          <div className="form-group">
                            <div className="change-avatar">
                              <div className="profile-img">
                                <img
                                  src="assets/img/user2.png"
                                  alt="User Image"
                                />
                              </div>
                              <div className="upload-img">
                                <h4>{name}</h4>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-6">
                          <div className="form-group">
                            <label>{t("name")}</label>
                            <input
                              className="form-control"
                              required
                              type="name"
                              placeholder={t("enterName")}
                              value={name}
                              onChange={(e) => setName(e.target.value)}
                            />
                          </div>
                        </div>

                        <div className="col-12 col-md-6">
                          <div className="form-group">
                            <label>{t("emailAddress")}</label>
                            <input
                              className="form-control"
                              required
                              type="email"
                              placeholder={t("enterEmail")}
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6">
                          <div className="form-group">
                            <label>{t("password")}</label>
                            <input
                              type="password"
                              placeholder={t("enterPassword")}
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6">
                          <div className="form-group">
                            <label>{t("confirmPassword")}</label>
                            <input
                              className="form-control"
                              type="password"
                              placeholder={t("confirmPassword")}
                              value={confirmPassword}
                              onChange={(e) =>
                                setConfirmPassword(e.target.value)
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div className="submit-section">
                        <button
                          type="submit"
                          className="btn btn-primary submit-btn"
                        >
                          {t("saveChanges")}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Index;
