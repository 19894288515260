import React, { useState, useEffect, useContext } from "react";
import Header from "../../components/Header/index";
import Footer from "../../components/Footer/index";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import P404 from "../p404";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import { createOrder } from "../../actions/orderAction";
import { ORDER_CREATE_RESET } from "../../constants/orderConstant";
import { useTranslation } from "react-i18next"; // Importing useTranslation hook
import { LanguageContext } from "../../components/TradeNameLangague/LanguageContext";

function Index() {
  const cart = useSelector((state) => state.cart);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation(); // Initializing the useTranslation hook
  const { selectedLanguage } = useContext(LanguageContext);
  console.log({ cart });
  const orderCreate = useSelector((state) => state.orderCreate);
  const { order, error, success } = orderCreate;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  console.log({ userInfo, cart });

  const sendOrder = () => {
    dispatch(
      createOrder({
        orderItems: cart.cartItems,
        shippingAddress: cart.shippingAddress,
        userEmail: userInfo.data.email, // Pass the user's email
      })
    );
  };

  useEffect(() => {
    if (success) {
      navigate(`/order/${order.data._id}`);
      dispatch({ type: ORDER_CREATE_RESET });
    }
  }, [navigate, success]);

  return (
    <div
      className="main-wrapper"
      style={{ backgroundColor: "#f1f7ff", minHeight: "700px" }}
    >
      <Header />
      <div class="content">
        <div className="container">
          <div class="comp-header">
            <h3 class="comp-title">{t("Shipping")}</h3>
            <div class="line"></div>
            <p>
              <br />
              {`${cart?.shippingAddress?.phone}, ${cart?.shippingAddress?.address}, ${cart?.shippingAddress?.city}, ${cart?.shippingAddress?.country}`}
            </p>
          </div>
          {cart.cartItems.length === 0 ? (
            <div class="alert alert-danger">
              {t("Your cart is empty")}
              {/* 
              <Link to="/">
                <i class="fas fa-arrow-left"></i>&nbsp;
                {t("Go Back")}
              </Link>
              */}
            </div>
          ) : (
            <>
              <div className="card card-table">
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table table-hover table-center mb-0">
                      <tbody>
                        {cart.cartItems.map((item, index) => (
                          <tr>
                            <td>{index + 1}</td>
                            <td>
                              <h2 className="table-avatar">
                                <Link
                                  to={`/products/${item.fishe}`}
                                  className="avatar avatar-xl me-2"
                                >
                                  <img
                                    className="avatar-img rounded-circle"
                                    src={item.image}
                                    alt={item.trade_name}
                                  />
                                </Link>
                                <Link to={`/products/${item.fishe}`}>
                                  {selectedLanguage === "fr"
                                    ? item.trade_name_fr
                                    : selectedLanguage === "es"
                                    ? item.trade_name_es
                                    : item.trade_name}
                                </Link>
                              </h2>
                            </td>
                            <td>
                              <div>
                                <h5>{`${item.qty} T`}</h5>
                              </div>
                            </td>
                            <td></td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div
                class={error ? "alert alert-danger" : ""}
                style={{ width: "300px" }}
              >
                {error}
              </div>

              <div class="submit-section proceed-btn text-end">
                <button
                  class="btn btn-primary submit-btn"
                  disabled={cart.cartItems.length === 0}
                  onClick={sendOrder}
                >
                  {t("Send Order")}
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default Index;
