import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Header from "../../components/Header";
import { useDispatch, useSelector } from "react-redux";
import { register } from "../../actions/userActions";
import { useNavigate, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Index() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState("");
  const dispatch = useDispatch();
  const location = useLocation();
  const redirect = location.search ? location.search.split("=")[1] : "/";

  const userRegister = useSelector((state) => state.userRegister);
  const { error, loading, userInfo } = userRegister;

  const user = JSON.parse(localStorage.getItem("userInfo"));

  const submitHandler = (e) => {
    e.preventDefault();

    if (!validateEmail(email)) {
      toast.error("Please enter a valid email address.");
    } else if (password !== confirmPassword) {
      toast.error(
        'The "Password" and "Confirm Password" fields must match.'
      );
    } else {
      dispatch(register(name, email, password));
    }
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const navigate = useNavigate();
  useEffect(() => {
    if (user) {
      navigate(redirect);
    }
  }, [navigate, userInfo, redirect]);

  return (
    <div className="main-wrapper" style={{ background: "#F1F7FF" }}>
      <div className="container login-body">
        <ToastContainer />
        <div className="container login-wrapper">
          <div className="loginbox ">
            <div className="login-left">
              <img
                className="img-fluid"
                src="assets/img/home-art1.jpg"
                alt="Logo"
              />
            </div>
            <div className="login-right">
              <div className="login-right-wrap">
                <h1>Register</h1>
                <p className="account-subtitle">Access to our dashboard</p>
                <form onSubmit={submitHandler}>
                  <div className="form-group">
                    <input
                      id="first-name"
                      type="text"
                      className="form-control"
                      required
                      placeholder="Name"
                      autoFocus
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <input
                      id="email"
                      type="email"
                      required
                      placeholder="Email"
                      className="form-control"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div className="row">
                    <div className="col-lg-6">
                      <div class="pass-group">
                        <input
                          id="password"
                          type="password"
                          className="form-control pass-input"
                          name="password"
                          placeholder="Password"
                          required
                          minLength={6}
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="form-group">
                        <input
                          id="password-confirm"
                          type="password"
                          placeholder="Confirm Password"
                          className="form-control"
                          required
                          name="password_confirmation"
                          value={confirmPassword}
                          onChange={(e) =>
                            setConfirmPassword(e.target.value)
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <button
                    className="btn btn-primary login-btn"
                    type="submit"
                    style={{ background: "#002D7F" }}
                  >
                    Create Account
                  </button>{" "}
                  <br />
                  <div className={error ? "alert alert-danger" : ""}>
                    {error}
                  </div>
                  <div className="account-footer text-center mt-3">
                    Already have an account?{" "}
                    <Link className="forgot-link mb-0" to={"/login"}>
                      Login
                    </Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Index;
