import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../components/Header/index";
import Footer from "../../components/Footer/index";
import { useLocation, useParams } from "react-router-dom";
import { listFishes, listFishbyType } from "../../actions/fisheActions";
import Card from "../../components/Fish/index";
import Pagination from "../../components/Fish/Paginate";
import P404 from "../p404";

function Index() {
  const location = useLocation();
  const dispatch = useDispatch();

  const { loading, fishes, page, pages, error } = useSelector(
    (state) => state.fisheList
  );
  
  const { fish,message } = useSelector((state) => state.fishByType);
 // const categorie = location.state?.categorie;
  const search = location.state?.search;
  const [libelle, setLibelle] = useState(search);
  
  useEffect(() => {
    setLibelle(search);
  }, [search]);

  useEffect(() => {
   dispatch(listFishes(libelle, 1, 6));
  }, [dispatch, libelle]);

  const searchAgain = () => {
    dispatch(listFishes(libelle,1,6));
    console.log(libelle);
  };
  const handlePageChange = (pageNumber) => {
    dispatch(listFishes("", pageNumber, 6)); // Update the keyword and page number, and fetch data
  };
  return (
    <>
      {error ? (
        <P404 />
      ) : (
        <div className="main-wrapper" style={{ backgroundColor: "#f1f7ff" }}>
          <Header />
          <br />
          <br />
            <>
              <div className="search-box">
                <form>
                  <div className="search-info location-search row">
                    {" "}
                    <input
                      type="text"
                      className="form-control text-truncate"
                      placeholder="Search "
                      onKeyUp={searchAgain}
                      value={libelle}
                      onChange={(e) => setLibelle(e.target.value)}
                    />
                  </div>
                </form>
              </div>
              <div class="content">
                <div class="container">
                  <div class="row">
                    <div class="col-xl-12 col-lg-12 order-md-last order-sm-last order-last map-left">
                      <div class="row">
                        {fishes.map((fishe) => (
                          <div
                            className="col-sm-6 col-md-4 col-xl-4"
                            key={fishe._id}
                            sm={12}
                            md={6}
                            lg={4}
                            xl={3}
                          >
                            <Card fishe={fishe} />
                          </div>
                        ))}{" "}
                      </div>
                      <div class="load-more text-center">
                        <Pagination
                          pages={pages}
                          page={page}
                          handlePageChange={handlePageChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          <Footer />
        </div>
      )}
    </>
  );
}

export default Index;
