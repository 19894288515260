import { HashRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import Index from "./pages/home";
import About from "./pages/about";
import Register from "./pages/register"
import Login from "./pages/login";
import Products from "./pages/products"
import Contact from "./pages/contact"
import DashboardAdmin from "./pages/user/admin/dashboard"
//import Translation from "./Translation/Translation";
import ProductsD from "./pages/user/admin/products";
import OrderAdmin from "./pages/user/admin/order";
import Profile from "./pages/user/admin/profile";
import FishDetails from "./pages/fish-details"
import OrderClient from "./pages/user/admin/orderClient";
import Users from "./pages/user/admin/users"
import P404 from "./pages/p404/index"
import ValidationMail from "./pages/ValidationMail"
import Order from "./pages/order/index"
import Shipping from "./pages/shipping"
import PlaceOrder from "./pages/placeOrder"
import Cart from "./pages/Cart"
import Subscribes from "./pages/user/admin/subscribes"
import Search from "./pages/search-products";
import { LanguageProvider } from "./components/TradeNameLangague/LanguageContext";

function App() {
  return (
    <LanguageProvider>
      <Router>
        <Routes>
          <Route exact path="/" element={<Index />} />
          <Route exact path="/about" element={<About />} />
          <Route exact path="/register" element={<Register />} />
          <Route exact path="/login" element={<Login />} />
          <Route
            exact
            path="/products/category/:categorie?"
            element={<Products />}
          />
          <Route
            exact
            path="/products"
            element={<Search />}
          />
          <Route exact path="/products/:id" element={<FishDetails />} />
          <Route exact path="/cart/:id?" element={<Cart />} />
          <Route exact path="/order/:id" element={<Order />} />
          <Route exact path="/shipping" element={<Shipping />} />
          <Route exact path="/placeorder" element={<PlaceOrder />} />
          <Route exact path="/contact" element={<Contact />} />
          <Route exact path="/dashboard" element={<DashboardAdmin />} />
          <Route exact path="/admin/products" element={<ProductsD />} />
          <Route exact path="/admin/order" element={<OrderAdmin />} />
          <Route exact path="/profile" element={<Profile />} />
          <Route exact path="/users" element={<Users />} />
          <Route exact path="/P404" element={<P404 />} />
          <Route exact path="/activation/:id" element={<ValidationMail />} />
          <Route exact path="/Order" element={<OrderClient />} />
          <Route exact path="/subscribes" element={<Subscribes />} />

          {/*
          <div className=" bg-dark hero ">
          <img src="assets/img/fish-modified.png" alt />
        </div>
         <p>
                  Nous sommes une entreprise mauritanienne spécialisée dans
                  l'exportation de produits de la pêche. Nous travaillons avec
                  des pêcheurs locaux pour fournir des produits frais et de
                  qualité supérieure à nos clients dans le monde entier.
                </p>
                <p>
                  Nous sommes fiers de soutenir l'industrie de la pêche en
                  Mauritanie en offrant des emplois stables et bien rémunérés à
                  nos employés et en respectant les normes de pêche durables.
                </p>
              </div>
              <div className="col-md-6">
                <h2 className="section-title">Notre équipe</h2>
                <p>
                  Notre équipe est composée de professionnels de l'exportation
                  de produits de la pêche avec une expérience approfondie dans
                  l'industrie. Nous sommes passionnés par notre travail et nous
                  efforçons de fournir un excellent service à nos clients.
                </p>
                <p>
                  Nous sommes heureux de répondre à toutes vos questions sur nos
                  produits et services. N'hésitez pas à nous contacter pour en
                  savoir plus sur ce que nous faisons.
                </p>
         */}
        </Routes>
      </Router>
    </LanguageProvider>
  );
}

export default App;
