export const ORDER_CREATE_REQUEST = 'ORDER_CREATE_REQUEST'
export const ORDER_CREATE_SUCCESS = 'ORDER_CREATE_SUCCESS'
export const ORDER_CREATE_FAIL = 'ORDER_CREATE_FAIL'

export const ORDER_CREATE_RESET = 'ORDER_CREATE_RESET'

export const ORDER_DETAILS_REQUEST = 'ORDER_DETAILS_REQUEST'
export const ORDER_DETAILS_SUCCESS = 'ORDER_DETAILS_SUCCESS'
export const ORDER_DETAILS_FAIL = 'ORDER_DETAILS_FAIL'


export const MYORDER_LIST_REQUEST = 'MYORDER_LIST_REQUEST'
export const MYORDER_LIST_SUCCESS = 'MYORDER_LIST_SUCCESS'
export const MYORDER_LIST_FAIL = 'MYORDER_LIST_FAIL'
export const MYORDER_LIST_RESET = 'MYORDER_LIST_RESET'



export const ORDER_LIST_REQUEST = "ORDER_LIST_REQUEST";
export const ORDER_LIST_SUCCESS = "ORDER_LIST_SUCCESS";
export const ORDER_LIST_FAIL = "ORDER_LIST_FAIL";

export const ORDER_DELETE_REQUEST = "ORDER_DELETE_REQUEST";
export const ORDER_DELETE_SUCCESS = "ORDER_DELETE_SUCCESS";
export const ORDER_DELETE_FAIL = "ORDER_DELETE_FAIL";

