import React, { useState, useEffect } from "react";
import axios from "axios";
import Sidebar from "../../../../components/sidebar";
import Header from "../../../../components/Header";
import Paginate from "../../../../components/Fish/Paginate";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation, Link } from "react-router-dom";
import {
  listFishes,
  deleteFishe,
  createFishe,
  listFishesDetails,
  updateFishe,
} from "../../../../actions/fisheActions";
import { FISHE_CREATE_RESET } from "../../../../constants/fisheConstant";
import { faKitchenSet } from "@fortawesome/free-solid-svg-icons";
function Index() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const fisheList = useSelector((state) => state.fisheList);
  const { loading, fishes, page, pages, error} = fisheList;
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const fisheDelete = useSelector((state) => state.fisheDelete);
  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete,
  } = fisheDelete;

  const fisheCreate = useSelector((state) => state.fisheCreate);
  const {
    loading: loadingCreate,
    error: errorCreate,
    success: successCreate,
    fishe: createdFishe,
  } = fisheCreate;

  const fisheUpdate = useSelector((state) => state.fisheUpdate);
  const {
    error: errorUpdate,
    loading: loadingUpdate,
    success: successUpdate,
  } = fisheUpdate;

  useEffect(() => {
    dispatch({ type: FISHE_CREATE_RESET });
    if (!userInfo || !userInfo.data || !userInfo.data.isAdmin) {
        navigate("/login");
    }

    if (successCreate && createdFishe) {
dispatch(listFishes("", page, 6)); 
    } else {
       dispatch(listFishes("", page, 6)); 
    }
  }, [
    dispatch,
    navigate,
    userInfo,
    successDelete,
    successCreate,
    createdFishe,
  ]);

  const getbyId = (id) => {
    console.log(id);
    dispatch(listFishesDetails(id));
  };
  const [libelle, setLibelle] = useState("");

  const fisheDetails = useSelector((state) => state.fisheDetails);
  const { fishe } = fisheDetails;
  const [trade_name, setTrade_Name] = useState(fishe ? fishe.trade_name : "");
  
  const [trade_name_fr, setTrade_Name_fr] = useState(fishe ? fishe.trade_name_fr : "");
  const [trade_name_es, setTrade_Name_es] = useState(fishe ? fishe.trade_name_es : "");
  const [classe, setClasse] = useState(fishe ? fishe.classe : "");
  const [category, setCategory] = useState(fishe ? fishe.category : "");
  const [image, setImage] = useState(fishe ? fishe.image : "");
 // const [size, setSize] = useState(fishe ? fishe.size : 0);
 // const [weight, setWeight] = useState(fishe ? fishe.weight : 0);
  const [countInStock, setCountInStock] = useState(
    fishe ? fishe.countInStock : 0
  );
  const [type, setType] = useState(fishe ? fishe.type : "fish");
  const [uploading, setUploading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);

  useEffect(() => {
    if (fishe) {
      setTrade_Name(fishe.trade_name);
      setTrade_Name_fr(fishe.trade_name_fr);
      setTrade_Name_es(fishe.trade_name_es);
      setClasse(fishe.classe);
      setCategory(fishe.category);
      setImage(fishe.image);
     // setSize(fishe.size);

    //  setWeight(fishe.weight);
      setCountInStock(fishe.countInStock);
      setType(fishe.type);
    }

  }, [fishe]);

  const submitHandler = (e) => {
    e.preventDefault();
     dispatch(
       updateFishe({
         _id: fishe._id,
         trade_name,
        trade_name_fr,
        trade_name_es,
         category,
         classe,
         image,
       //  size,
       //  weight,
         countInStock,
         type,
       })
     );
  
     dispatch(listFishes("", page, 6)); 
  };
    const formData = new FormData();

  const createFisheHandler = (e) => {
    e.preventDefault();

    formData.append("trade_name", trade_name);
    formData.append("trade_name_fr", trade_name_fr);
    formData.append("trade_name_es", trade_name_es);
    formData.append("classe", classe);
    formData.append("category", category);
    formData.append("image", selectedFile);
   // formData.append("size", size);
  //  formData.append("weight", weight);
    formData.append("countInStock", countInStock);
    formData.append("type", type);
    dispatch(createFishe(formData));
    // dispatch(listFishes("", page, 6)); 
    //dispatch(createFishe());
  };
 
  const deleteHandler = () => {
    if (fishe && fishe._id) {
      dispatch(deleteFishe(fishe._id));
    }
  };
  //img
  const uploadFileHandler = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();

    formData.append("image", file);
    formData.append("fishe_id", fishe._id);

    setUploading(true);

    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      const { data } = await axios.post(
        "/api/fishes/upload/",
        formData,
        config
      );
      setImage(data);
      setUploading(false);
    } catch (error) {
      setUploading(false);
    }
  };
  const handlePageChange = (pageNumber) => {
    dispatch(listFishes("", pageNumber, 6)); // Update the keyword and page number, and fetch data
  };
    const searchAgain = () => {
      dispatch(listFishes(libelle, 1, 6));
      console.log(libelle);
    };
  return (
    <body>
      <div className="main-wrapper">
        <Header />
        <br />
        <div className="breadcrumb-bar">
          <div className="container-fluid">
            <div className="row align-items-center">
              <div className="col-md-12 col-12">
                <nav aria-label="breadcrumb" className="page-breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="/">Home</a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Dashboard
                    </li>
                  </ol>
                </nav>
                <h2 className="breadcrumb-title">Products</h2>
              </div>
            </div>
          </div>
        </div>
        <div className="page-wrapper">
          <div className="content container-fluid">
            <div className="row">
              <Sidebar />
              <div className="col-md-7 col-lg-8 col-xl-9">
                <div className="row">
                  <div className="col-8">
                    <a
                      data-bs-toggle="modal"
                      href="#add-modal"
                      className="btn btn-primary me-2"
                      style={{ marginLeft: "15px" }}
                    >
                      <i className="fe fe-check" /> Add
                    </a>
                  </div>
                  <div className="col-4">
                    <input
                      type="text"
                      className="form-control"
                      style={{ width: "300px" }}
                      onKeyUp={searchAgain}
                      value={libelle}
                      onChange={(e) => setLibelle(e.target.value)}
                    />
                  </div>
                </div>
                <br />
                <br />
                <div className="row">
                  <div className="col-sm-12">
                    <div className="card">
                      <div className="card-body">
                        <div className="table-responsive">
                          <table className="datatable table table-hover table-center mb-0">
                            <thead>
                              <tr>
                                <th>Nom comercial</th>
                                <th>Classe</th>
                                <th>Famille</th>                               
                                <th>Type</th>
                                <th className="text-center"></th>
                              </tr>
                            </thead>
                            <tbody>
                              {fishes && fishes.length > 0 ? (
                                <>
                                  {fishes.map((fish) => (
                                    <tr>
                                      <td>
                                        <h2 class="table-avatar">
                                          <Link
                                            to={`/products/${fish._id}`}
                                            class="avatar avatar-sm me-2"
                                          >
                                            <img
                                              class="avatar-img rounded-circle"
                                              src={fish.image}
                                              alt=""
                                            />
                                          </Link>
                                          <Link
                                            to={`/products/${fish._id}`}
                                            className="a"
                                          >
                                            {" "}
                                            {fish.trade_name}
                                            <span>
                                              <span
                                                class="__cf_email__"
                                                data-cfemail="76020f04191813041914130402053617121914135815191b"
                                              >
                                                {fish.trade_name_fr},
                                                {fish.trade_name_es}
                                              </span>
                                            </span>
                                          </Link>
                                        </h2>
                                      </td>
                                      <td>{fish.classe}</td>
                                      <td>{fish.category}</td>

                                     
                                      <td>{fish.type}</td>
                                     
                                      <td className="text-end">
                                        <div className="actions">
                                          <a
                                            data-bs-toggle="modal"
                                            href="#edit_invoice_report"
                                            className="btn btn-sm bg-success-light me-2"
                                            onClick={() => getbyId(fish._id)}
                                          >
                                            <i className="fe fe-pencil" /> Edit
                                          </a>
                                          <a
                                            className="btn btn-sm bg-danger-light"
                                            data-bs-toggle="modal"
                                            href="#delete_modal"
                                            onClick={() => getbyId(fish._id)}
                                          >
                                            <i className="fe fe-trash" /> Delete
                                          </a>
                                        </div>
                                      </td>
                                    </tr>
                                  ))}
                                  <Paginate
                                    pages={pages}
                                    page={page}
                                    handlePageChange={handlePageChange}
                                  />
                                </>
                              ) : (
                                " "
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="add-modal"
        aria-hidden="true"
        role="dialog"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">ADD Product</h5>
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={createFisheHandler}>
                <div className="row form-row">
                  <div className="col-12 col-sm-6">
                    <div className="form-group">
                      <label>Nom comercial</label>
                      <input
                        type="text"
                        className="form-control"
                        required
                        onChange={(e) => setTrade_Name(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-sm-6">
                    <div className="form-group">
                      <label>Nom comercial French</label>
                      <input
                        type="text"
                        className="form-control"
                        required
                        // value={trade_name || ""}
                        onChange={(e) => setTrade_Name_fr(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-sm-6">
                    <div className="form-group">
                      <label>Nom comercial spanish</label>
                      <input
                        type="text"
                        className="form-control"
                        required
                        // value={trade_name || ""}
                        onChange={(e) => setTrade_Name_es(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-sm-6">
                    <div className="form-group">
                      <label>Image</label>
                      <input
                        type="file"
                        className="form-control"
                        accept="image/*"
                        onChange={(e) => setSelectedFile(e.target.files[0])}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-sm-6">
                    <div className="form-group">
                      <label>Famille</label>
                      <input
                        type="text"
                        className="form-control"
                        required
                        // value={category || ""}
                        onChange={(e) => setCategory(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-sm-6">
                    <div className="form-group">
                      <label>Classe</label>
                      <input
                        type="text"
                        className="form-control"
                        required
                        // value={classe || ""}
                        onChange={(e) => setClasse(e.target.value)}
                      />
                    </div>{" "}
                  </div>
                  <div className="col-12 col-sm-6">
                    <div className="form-group">
                      <label>Stock</label>
                      <input
                        type="number"
                        required
                        className="form-control"
                        //  value={countInStock || ""}
                        onChange={(e) => setCountInStock(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-sm-6">
                    <div className="form-group">
                      <label>Type</label>
                      <select
                        class="form-control select"
                        name="subcategory"
                        // value={type || ""}
                        onChange={(e) => {
                          setType(e.target.value);
                        }}
                        SelectProps={{
                          native: true,
                        }}
                      >
                        <option value="fish">Select...</option>
                        <option value="seafood">seafood</option>
                        <option value="fish">fish</option>
                      </select>
                    </div>
                  </div>
                </div>
                <button
                  type="submit"
                  className="btn btn-primary btn-block w-100"
                 
                  data-bs-dismiss="modal"
                //  data-bs-dismiss={successCreate ? "modal" : ""}
                >
                  Save
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="edit_invoice_report"
        aria-hidden="true"
        role="dialog"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Edit Product</h5>
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={submitHandler}>
                <div className="row form-row">
                  <div className="col-12 col-sm-6">
                    <div className="form-group">
                      <label>Nom comercial</label>
                      <input
                        type="text"
                        className="form-control"
                        required
                        value={trade_name || ""}
                        onChange={(e) => setTrade_Name(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-sm-6">
                    <div className="form-group">
                      <label>Nom comercial French</label>
                      <input
                        type="text"
                        className="form-control"
                        required
                        value={trade_name_fr || ""}
                        onChange={(e) => setTrade_Name_fr(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-sm-6">
                    <div className="form-group">
                      <label>Nom comercial spanish</label>
                      <input
                        type="text"
                        required
                        className="form-control"
                        value={trade_name_es || ""}
                        onChange={(e) => setTrade_Name_es(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-sm-6">
                    <div className="form-group">
                      <label>Famille</label>
                      <input
                        type="text"
                        required
                        className="form-control"
                        value={category || ""}
                        onChange={(e) => setCategory(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-sm-6">
                    <div className="form-group">
                      <label>Classe</label>
                      <input
                        type="text"
                        required
                        className="form-control"
                        value={classe || ""}
                        onChange={(e) => setClasse(e.target.value)}
                      />
                    </div>{" "}
                  </div>{" "}
                  <div className="col-12 col-sm-6">
                    <div className="form-group">
                      <label>Image</label>
                      <input
                        type="file"
                        custom
                        className="form-control"
                        onChange={uploadFileHandler}
                      />
                    </div>
                  </div>
                  
                  <div className="col-12 col-sm-6">
                    <div className="form-group">
                      <label>Stock</label>
                      <input
                        type="number"
                        required
                        className="form-control"
                        value={countInStock || ""}
                        onChange={(e) => setCountInStock(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-sm-6">
                    <div className="form-group">
                      <label>Type</label>
                      <select
                        class="form-control select"
                        name="subcategory"
                        value={type || ""}
                        onChange={(e) => {
                          setType(e.target.value);
                        }}
                        SelectProps={{
                          native: true,
                        }}
                      >
                        <option value="">Select...</option>
                        <option value="seafood">seafood</option>
                        <option value="fish">fish</option>
                      </select>
                    </div>
                  </div>
                </div>
                <button
                  type="submit"
                  className="btn btn-primary btn-block w-100"
                  data-bs-dismiss="modal"
                >
                  Save Changes
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="delete_modal"
        aria-hidden="true"
        role="dialog"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content" style={{ textAlign: "center" }}>
            <div className="modal-body">
              <div
                className="form-content p-2"
                style={{ alignContent: "center" }}
              >
                <h4 className="modal-title">Delete</h4>
                <p className="mb-4">Are you sure want to delete?</p>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => deleteHandler()}
                  data-bs-dismiss="modal"
                >
                  Delete{" "}
                </button>
                <button
                  type="button"
                  className="btn btn-danger"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </body>
  );
}

export default Index;