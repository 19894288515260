import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
//import { verify } from "../../features/verified/verifySlice";
import { useSelector, useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useNavigate } from "react-router-dom";
import P404 from "../p404/index";

//vérifiez votre e-mail pour terminer l'inscription à Taining4All

function Mail() {
  let { id } = useParams();
  const { code } = useParams();
  const dispatch = useDispatch();
  var MonTableau = [id, code];

  useEffect(() => {
    //dispatch(verify(MonTableau));
  }, [id, code]);

  const user = true
  const rejected = false
  const pending= false

  //useSelector((state) => state.verify.user);
  //const { pending, message, rejected } = useSelector((state) => state.verify);
  
  return (
    <div>
      <body>
        <div class="main-wrapper">
          <div class="content success-page-cont">
            {pending ? (
              <h1 style={{ margin: "200px", textAlign: "center" }}>
                {" "}
                please wait ....
              </h1>
            ) : (
              <div class="container-fluid">
                {user ? (
                  <div class="row justify-content-center">
                    <div class="col-lg-6">
                      <div class="card success-card">
                        <div class="card-body ">
                          <div
                            class="success-cont"
                            style={{ align: "center", padding: "50px" }}
                          >
                            <div>
                              <i class="fas fa-check"></i>
                              <h2>Votre compte est vérifié avec succès !</h2>
                              {/*
                                <p>
                                Appointment booked with{" "}
                                <strong>Darren Elder</strong>
                                <br /> on{" "}
                                <strong>12 Nov 2019 5:00PM to 6:00PM</strong>
                              </p>
                               */}
                              <Link
                                to="/login"
                                class="btn btn-primary view-inv-btn"
                              >
                                retour à la page de connexion
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div>
                    <P404 />
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </body>
    </div>
  );
}

export default Mail;
/*
 <div class="login-wrapper">
        {pending ? (
          <h1>please wait ... </h1>
        ) : (
          <div className="container-fluid">
            {user ? (
              <div>
                {" "}
                <h1 style={{ textAlign: "center" }}>
                  {" "}
                  <p404 />
                  L'utilisateur déjà vérifié{" "}
                </h1>
              </div>
            ) : (
              <div className="loginbox">
                <div style={{ margin: "10px", borderRadius: "20px" }}>
                  <div class="card-body">
                    <img
                      className="img-fluid"
                      src="assetsAdmin/img/logoT.png"
                      style={{ maxWidth: "150px" }}
                      alt="Logo"
                    />
                    <br />
                    
                    <img
                      className="img-fluid"
                      src="assetsAdmin/img/mail1.png"
                      style={{ maxWidth: "350px", textAlign: "center" }}
                      alt="e-mail_img"
                    />
                    
                    <img
                      src="assetsAdmin/img/success.png"
                      alt="success_img"
                      className="img-fluid"
                    />
                    <button
                      className="btn btn-warning btn-lg active btn-outline-active"
                      type="submit"
                    >
                      Vérifiez votre e-mail &nbsp;
                    </button>
                  </div>
                </div>{" "}
              </div>
            )}
          </div>
        )}
      </div>
*/
