import React, { useEffect, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, NavDropdown } from "react-bootstrap";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { logout } from "../../actions/userActions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCartShopping } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { LanguageContext } from "../TradeNameLangague/LanguageContext";
//import "./index.css";

const currencies = [
  {
    value: "en",
    label: "en",
  },
  {
    value: "fr",
    label: "fr",
  },
  {
    value: "sp",
    label: "sp",
  },
];
const Index = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const cart = useSelector((state) => state.cart);
  const { cartItems } = cart;
  console.log(userInfo);
  const dispatch = useDispatch();
  const logoutHandler = () => {
    dispatch(logout());
  };
  
  const { selectedLanguage, setSelectedLanguage } = useContext(LanguageContext);
  const handleLanguageChange = (language) => {
    console.log(language);
    setSelectedLanguage(language);
    i18n.changeLanguage(language);
  };

  const SearchByType = () => {
     console.log();
     navigate(`/products`);
   };
  return (
    <div>
      {userInfo ? (
        <>
          {userInfo.data.isAdmin == true ? (
            <header className="header header-five">
              <div className="header-fixed">
                <nav className="navbar navbar-expand-lg header-nav">
                  <div className="navbar-header">
                    <a id="mobile_btn" href="javascript:void(0);">
                      <span className="bar-icon">
                        <span />
                        <span />
                        <span />
                      </span>
                    </a>
                    <Link to="/" className="navbar-brand logo">
                      <img
                        src="assets/img/logo.png"
                        className="img-fluid"
                        alt="Logo"
                      />
                    </Link>
                  </div>
                  <div className="main-menu-wrapper">
                    <div className="menu-header">
                      <Link to="/profile" className="menu-logo">
                        <img
                          src="assets/img/user2.png"
                          className="img-fluid"
                          alt="Logo"
                        />
                      </Link>
                      <a
                        id="menu_close"
                        className="menu-close"
                        href="javascript:void(0);"
                      >
                        <i
                          className="fas fa-times"
                          style={{ color: "#002D7F" }}
                        />
                      </a>
                    </div>
                    <ul className="main-nav">
                      <li className="submenu">
                        <Link to="/">{t("Home")} </Link>
                      </li>
                      <li className="submenu active">
                        <a onClick={() => SearchByType()}>
                          {t("Products")}{" "}
                        </a>
                      </li>
                      <li className="submenu active">
                        <Link to="/about">{t("About Us")} </Link>
                      </li>
                      <li className="submenu active">
                        <Link to="/contact">{t("Contact")} </Link>
                      </li>
                      <li>
                        {" "}
                        <select
                          className="btn select"
                          name="subcategory"
                          title={selectedLanguage.toUpperCase()}
                          onChange={(e) => handleLanguageChange(e.target.value)}
                          SelectProps={{
                            native: true,
                          }}
                        >
                          <option
                          //onClick={() => handleLanguageChange("en")}
                          >
                            {t("en")}
                          </option>
                          <option // onClick={() => handleLanguageChange("fr")}
                          >
                            {t("fr")}
                          </option>
                          <option
                          //onClick={() => handleLanguageChange("es")}
                          >
                            {t("es")}
                          </option>
                        </select>
                      </li>
                    </ul>
                  </div>
                  <ul className="nav header-navbar-rht">
                    <li className="nav-item dropdown arrow logged-item">
                      <a
                        href="#"
                        className="dropdown-toggle nav-link"
                        data-bs-toggle="dropdown"
                      >
                        <span className="user-img">
                          <i class="fas fa-user" className="rounded-circle"></i>
                          <img
                            className="rounded-circle"
                            src="assets/img/user2.png"
                            width={31}
                            alt="user"
                          />
                        </span>
                      </a>
                      <div className="dropdown-menu dropdown-menu-end">
                        <div className="user-header">
                          <div className="avatar avatar-sm">
                            <img
                              src="assets/img/user2.png"
                              alt="User Image"
                              className="avatar-img rounded-circle"
                            />
                          </div>
                          <div className="user-text">
                            <h6>{userInfo.data.name}</h6>
                            <p>Administrateur</p>
                          </div>
                        </div>
                        <Link className="dropdown-item" to="/dashboard">
                          Dashboard
                        </Link>
                        <Link className="dropdown-item" to="/profile">
                          {t("profile")}
                        </Link>
                        <Link
                          className="dropdown-item"
                          to="/login"
                          onClick={logoutHandler}
                          style={{
                            color: "#e72d46",
                          }}
                        >
                          {t("Logout")}
                        </Link>
                      </div>
                    </li>
                  </ul>
                </nav>
              </div>
            </header>
          ) : userInfo.data.isAdmin == false ? (
            <header className="header header-five">
              <div className="header-fixed">
                <nav className="navbar navbar-expand-lg header-nav">
                  <div className="navbar-header">
                    <a id="mobile_btn" href="javascript:void(0);">
                      <span className="bar-icon">
                        <span />
                        <span />
                        <span />
                      </span>
                    </a>

                    <Link to="/" className="navbar-brand logo">
                      <img
                        src="assets/img/logo.png"
                        className="img-fluid"
                        alt="Logo"
                      />
                    </Link>
                  </div>
                  <div className="main-menu-wrapper">
                    <div className="menu-header">
                      <Link to="/profile" className="menu-logo">
                        <img
                          src="assets/img/user2.png"
                          className="img-fluid"
                          alt="Logo"
                        />
                      </Link>
                      <a
                        id="menu_close"
                        className="menu-close"
                        href="javascript:void(0);"
                      >
                        <i
                          className="fas fa-times"
                          style={{ color: "#002D7F" }}
                        />
                      </a>
                    </div>
                    <ul className="main-nav">
                      <li className="submenu">
                        <Link to="/">{t("Home")} </Link>
                      </li>
                      <li className="submenu">
                        <a onClick={() => SearchByType()}>{t("Products")} </a>
                      </li>
                      <li className="submenu">
                        <Link to="/about">{t("About Us")} </Link>
                      </li>
                      <li className="submenu">
                        <Link to="/Contact">{t("Contact")} </Link>
                      </li>
                      <li className="submenu">
                        <Link
                          to="/cart"
                          style={{
                            position: "relative",
                            display: "inline-block",
                          }}
                        >
                          <img
                            src="assets/img/chariot.png"
                            alt="cartImg"
                            style={{ width: "35px" }}
                          />
                          <span
                            style={{
                              position: "absolute",
                              top: "-5px",
                              left: "20px",
                              fontSize: "12px",
                              fontWeight: "600",
                              color: "white",
                              backgroundColor: "#47A0D1",
                              borderRadius: "50%",
                              width: "20px",
                              height: "20px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            {cartItems.length === 0 ? 0 : cartItems.length}
                          </span>
                        </Link>
                      </li>
                      <li>
                        {" "}
                        <select
                          className="btn select"
                          name="subcategory"
                          title={selectedLanguage.toUpperCase()}
                          onChange={(e) => handleLanguageChange(e.target.value)}
                          SelectProps={{
                            native: true,
                          }}
                        >
                          <option
                          //onClick={() => handleLanguageChange("en")}
                          >
                            {t("en")}
                          </option>
                          <option // onClick={() => handleLanguageChange("fr")}
                          >
                            {t("fr")}
                          </option>
                          <option
                          //onClick={() => handleLanguageChange("es")}
                          >
                            {t("es")}
                          </option>
                        </select>
                      </li>
                    </ul>
                  </div>
                  <ul className="nav header-navbar-rht">
                    <li className="nav-item dropdown arrow logged-item">
                      <a
                        href="#"
                        className="dropdown-toggle nav-link"
                        data-bs-toggle="dropdown"
                      >
                        <span className="user-img">
                          <img
                            className="rounded-circle"
                            src="assets/img/user2.png"
                            width={31}
                            alt="User"
                          />
                        </span>
                      </a>
                      <div className="dropdown-menu dropdown-menu-end">
                        <div className="user-header">
                          <div className="avatar avatar-sm">
                            <img
                              src="assets/img/user2.png"
                              alt="User Image"
                              className="avatar-img rounded-circle"
                            />
                          </div>
                          <div className="user-textU">
                            <h6>{userInfo.data.name}</h6>
                          </div>
                        </div>
                        <Link className="dropdown-item" to="/Order">
                          {t("orders")}
                        </Link>
                        <Link className="dropdown-item" to="/profile">
                          {t("profile")}
                        </Link>
                        <Link
                          className="dropdown-item"
                          to="/login"
                          onClick={logoutHandler}
                          style={{
                            color: "#e72d46",
                          }}
                        >
                          {t("Logout")}
                        </Link>
                      </div>
                    </li>
                  </ul>
                </nav>
              </div>
            </header>
          ) : (
            ""
          )}
        </>
      ) : (
        <header className="header header-five">
          <div className="header-fixed">
            <nav className="navbar navbar-expand-lg header-nav">
              <div className="container">
                <div className="navbar-header">
                  <a
                    id="mobile_btn" //href="#"
                  >
                    <span className="bar-icon">
                      <span />
                      <span />
                      <span />
                    </span>
                  </a>
                  <Link to="/" className="navbar-brand logo">
                    <img
                      src="assets/img/logo.png"
                      className="img-fluid"
                      alt="Logo"
                    />
                  </Link>
                </div>
                <div className="main-menu-wrapper">
                  <div className="menu-header">
                    <Link to="/" className="menu-logo">
                      <img
                        src="assets/img/logo.png"
                        className="img-fluid"
                        alt="Logo"
                      />
                    </Link>
                    <a id="menu_close" className="menu-close" href="#">
                      <i
                        className="fas fa-times"
                        style={{ color: "#002D7F" }}
                      />
                    </a>
                  </div>
                  <ul className="main-nav">
                    <li className="has-submenu">
                      <Link to="/" id="menu_close">
                        {t("Home")}
                      </Link>
                    </li>
                    <li className="submenu">
                      <a onClick={() => SearchByType()} id="menu_close">
                        {t("Products")}
                      </a>
                    </li>

                    <li className="submenu">
                      <Link to="/about" id="menu_close">
                        {t("About")}
                      </Link>
                    </li>
                    <li className="submenu">
                      <Link to="/Contact" id="menu_close">
                        {t("Contact")}
                      </Link>
                    </li>
                    <li className="login-link">
                      <Link
                        to="/cart"
                        style={{
                          position: "relative",
                          display: "inline-block",
                        }}
                      >
                        <img
                          src="assets/img/chariot.png"
                          alt="cartImg"
                          style={{
                            width: "35px",
                            color: "#fff",
                          }}
                        />
                        <span
                          style={{
                            position: "absolute",
                            top: "-5px",
                            left: "20px",
                            fontSize: "12px",
                            fontWeight: "600",
                            color: "#fff",
                            backgroundColor: "#47A0D1",
                            borderRadius: "50%",
                            width: "20px",
                            height: "20px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {cartItems.length === 0 ? 0 : cartItems.length}
                        </span>
                      </Link>
                    </li>
                    <li className="login-link">
                      {" "}
                      <select
                        className="btn select"
                        name="subcategory"
                        title={selectedLanguage.toUpperCase()}
                        onChange={(e) => handleLanguageChange(e.target.value)}
                        SelectProps={{
                          native: true,
                        }}
                      >
                        <option
                        //onClick={() => handleLanguageChange("en")}
                        >
                          {t("en")}
                        </option>
                        <option // onClick={() => handleLanguageChange("fr")}
                        >
                          {t("fr")}
                        </option>
                        <option
                        //onClick={() => handleLanguageChange("es")}
                        >
                          {t("es")}
                        </option>
                      </select>
                    </li>
                    <li className="login-link">
                      <Link to="/login" id="menu_close">
                        {t("Login")} / {t("SignUp")}
                      </Link>
                    </li>
                  </ul>
                </div>

                <ul className="nav header-navbar-rht">
                  <li className="nav-item">
                    <Link
                      to="/cart"
                      style={{ position: "relative", display: "inline-block" }}
                    >
                      <img
                        src="assets/img/chariot.png"
                        alt="cartImg"
                        style={{ width: "35px" }}
                      />
                      <span
                        style={{
                          position: "absolute",
                          top: "-5px",
                          left: "20px",
                          fontSize: "12px",
                          fontWeight: "600",
                          color: "white",
                          backgroundColor: "#47A0D1",
                          borderRadius: "50%",
                          width: "20px",
                          height: "20px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {cartItems.length === 0 ? 0 : cartItems.length}
                      </span>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/login">
                      {t("Login")}
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link header-login" to="/register">
                      {t("SignUp")}
                    </Link>
                  </li>
                  <li class="active has-submenu">
                    <select
                      className="btn select"
                      name="subcategory"
                      title={selectedLanguage.toUpperCase()}
                      onChange={(e) => handleLanguageChange(e.target.value)}
                      SelectProps={{
                        native: true,
                      }}
                    >
                      <option
                      //onClick={() => handleLanguageChange("en")}
                      >
                        {t("en")}
                      </option>
                      <option // onClick={() => handleLanguageChange("fr")}
                      >
                        {t("fr")}
                      </option>
                      <option
                      //onClick={() => handleLanguageChange("es")}
                      >
                        {t("es")}
                      </option>
                    </select>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </header>
      )}
    </div>
  );
};
export default Index;
