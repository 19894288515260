import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next"; // Import the useTranslation hook
import Header from "../../components/Header/index";
import Footer from "../../components/Footer/index";
import { getOrderDetails } from "../../actions/orderAction";
import P404 from "../p404";
import moment from "moment";
import "./index.css"
function Index() {
  const { t } = useTranslation(); // Initialize the useTranslation hook
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const orderDetails = useSelector((state) => state.orderDetails);
  const { order, error, loading } = orderDetails;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    if (!order || !order.data || order.data._id !== Number(id)) {
      console.log(id);
      dispatch(getOrderDetails(id));
    }
  }, [order, id]);

  const dateString = "2023-05-24T17:12:06.682591Z";
  const dateObj = new Date(dateString);

  console.log(dateObj);

  return error ? (
    <P404 />
  ) : (
    <div
      className="main-wrapper"
      style={{ backgroundColor: "#f1f7ff", minHeight: "700px" }}
    >
      <Header />
      <div class="content">
        <div class="container-fluid">
          <div class="row">
            <div class="col-lg-8 offset-lg-2">
              <div class="invoice-content">
                <div class="invoice-item">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="invoice-logo">
                        <img src="assets/img/logo.png" alt="logo" />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <p class="invoice-details">
                        <strong>{t("Order")}: </strong> {order?.data._id}
                        <br />
                        <strong>{t("Date")}: </strong>
                        {moment(order?.data.created_at).format(
                          "DD-MM-YYYY hh:mm"
                        )}
                      </p>
                    </div>
                  </div>
                </div>
                <div class="invoice-item">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="invoice-info">
                        <strong class="customer-text">{t("Billed to")}</strong>
                        <p class="invoice-details invoice-details-two">
                          {t("Name")}: {order?.data.user.name}
                          <br />
                          {t("Email")}:&ensp;
                          <a
                            href={`mailto:${order?.data?.user.email}`}
                            className="a"
                          >
                            <span>
                              <span
                                className="__cf_email__"
                                data-cfemail="214753404f4244526140454e43440f424e4c"
                              >
                                {order?.data?.user.email}
                              </span>
                            </span>
                          </a>
                        </p>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="invoice-info invoice-info2">
                        <strong class="customer-text">{t("Address")}</strong>
                        <p class="invoice-details">
                          {order?.data.shippingAddress.phone} ,
                          {order?.data.shippingAddress.address} ,
                          {order?.data.shippingAddress.city}{" "}
                          {order?.data.shippingAddress.postalCode},{" "}
                          {order?.data.shippingAddress.country} <br />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="invoice-item">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="invoice-info">
                        <strong class="customer-text">
                          {t("ASK FOR A QUOTE")}
                        </strong>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="invoice-item invoice-table-wrap">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="table-responsive">
                        <table class="invoice-table table table-bordered">
                          <thead>
                            <tr>
                              <th>{t("Products Requested")}</th>
                              <th class="text-center">{t("Quantity")}</th>
                              <th class="text-center">{t("Price")}</th>
                              <th class="text-end">{t("Total")}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {order?.data.orderItems.map((item, index) => (
                              <tr>
                                <td>
                                  {" "}
                                  <img
                                    className="avatar avatar-sm me-2 avatar-img rounded-circle"
                                    src={item.image}
                                    alt="Image"
                                  />
                                  {item.name}
                                </td>
                                <td class="text-center"> {item.qty} T</td>
                                <td class="text-center">---</td>
                                <td class="text-end">---</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div class="col-md-6 col-xl-4 ms-auto">
                      <div class="table-responsive">
                        <table class="invoice-table-two table">
                          <tbody>
                            <tr>
                              <th>{t("Subtotal")}:</th>
                              <td>
                                <span>---</span>
                              </td>
                            </tr>
                            <tr>
                              <th>{t("Total Amount")}:</th>
                              <td>
                                <span>---</span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="other-info">
                  <h4>{t("Other informations")}</h4>
                  <p class="text-muted mb-0">{t("dear customer")}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Index;
