import React, { useState, useEffect } from "react";
import Header from "../../components/Header/index";
import Footer from "../../components/Footer/index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShrimp, faFish } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { listFishes } from "../../actions/fisheActions";
import Fishe from "../../components/Fish/index-home";
import Carousel from "../../components/Fish/carousel.js";
import P404 from "../p404";
import Pagination from "../../components/Fish/Paginate";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";

function Index() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [Libelle, setLibelle] = useState("");
const [email, setEmail] = useState("");
  useEffect(() => {
    dispatch(listFishes("", 1, 5));
  }, [dispatch]);

  const Search = () => {
    console.log(Libelle);
    navigate("/products", { state: { search: Libelle } });
  };

  const SearchByType = (type) => {
    console.log(type);
    navigate(`/products/category/${type}`);
  };

  const { loading, fishes, page, pages, error } = useSelector(
    (state) => state.fisheList
  );

  const handlePageChange = (pageNumber) => {
    dispatch(listFishes("", pageNumber, 5)); // Update the keyword and page number, and fetch data
  };
const handleSubscribe = async () => {
  if (!email) {
    toast.error("Please enter an email address");
    return;
  }

  // Email validation regex pattern
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const gmailRegex = /@gmail\.com$/i;

  if (!emailRegex.test(email)) {
    toast.error("Please enter a valid email address");
    return;
  }

  if (!gmailRegex.test(email)) {
    toast.error("Please enter a Gmail address");
    return;
  }

  try {
    const response = await axios.post(
      "/api/subscribe/",
      { email },
      {
        headers: {
          "Content-type": "application/json",
        },
      }
    );

    console.log(response.data); // Handle the response as needed
    setEmail(""); // Reset the email input field after successful subscription
     toast.success("Thank you for subscribing!");
  } catch (error) {
    console.log(error.response.data); // Handle the error as needed
  }
}; 
  return (
    <div>
      {error ? (
        <P404 />
      ) : (
        <div className="main-wrapper">
          <ToastContainer />
          <Header />
          <section className="home-slide home-slide-five d-flex align-items-center">
            <div className="container">
              <div className="row ">
                <div className="col-md-6 d-flex align-items-center">
                  <div className="home-slide-face aos">
                    <div className="home-slide-text ">
                      <a href="#/products" className="btn bg-stop-learn">
                        {t("Shop for fresh fish")}
                      </a>
                      <h1 style={{ color: "#002D7F" }}>
                        {t("Fish & Seafood")}
                        <br />
                        <span>{t("fresh and frozen")}</span>
                        <br />
                      </h1>
                      <p>
                        {t("Everything you need to buy fish & seafood online")}
                      </p>
                    </div>
                    <div className="search-box">
                      <form>
                        <div className="form-group search-info location-search">
                          <input
                            type="text"
                            className="form-control text-truncate"
                            placeholder={t("Search fish | Search seafood, etc")}
                            value={Libelle}
                            onChange={(e) => setLibelle(e.target.value)}
                          />
                          <button
                            className="btn bg-search search-btn align-items-center d-flex justify-content-center"
                            onClick={Search}
                          >
                            {t("Search")}
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 d-flex align-items-end">
                  <div className="girl-slide-img aos ">
                    <img
                      src="assets/img/poulpe.png"
                      style={{ size: "100px" }}
                      alt
                    />
                  </div>
                </div>
              </div>
              <div className="slide-background">
                <img src="assets/img/slide-bg.png" alt />
              </div>
            </div>
          </section>
          <section className="most-popular most-popular-five">
            <div className="container">
              <div className="section-header section-head-left aos ">
                <h2>{t("Most Popular Categories")}</h2>
                <p className="sub-title"></p>
                <div className="view-all ">
                  <a href="#/products">
                    {t("View all")}{" "}
                    <i className="fa fa-arrow-right" aria-hidden="true" />
                  </a>
                </div>
              </div>
              <div className="popular-categories aos ">
                <div className="row">
                  <div className="col-lg-6 col-md-6">
                    <a
                      defaultValue="seafood"
                      onClick={() => SearchByType("seafood")}
                    >
                      <div className="sub-categories d-flex align-items-center">
                        <div className="categories-img">
                          <FontAwesomeIcon
                            icon={faShrimp}
                            style={{ color: "#16478E", size: "500px" }}
                            size="2xl"
                          />
                        </div>
                        <div className="categories-text ">
                          <h4>{t("Seafood")}</h4>
                        </div>
                      </div>
                    </a>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <a defaultValue="fish" onClick={() => SearchByType("fish")}>
                      <div className="sub-categories d-flex align-items-center">
                        <div className="categories-img">
                          <FontAwesomeIcon
                            icon={faFish}
                            style={{ color: "#16478E" }}
                            size="2xl"
                          />
                        </div>
                        <div className="categories-text ">
                          <h4>{t("Fish")}</h4>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="section trending-courses trending-courses-five">
            <div className="container">
              <div className="section-header section-head-left aos ">
                <h2>{t("Most Trending")}</h2>
                <p className="sub-title"></p>
              </div>
              <Carousel style={{ height: "400px" }} />
            </div>
          </section>
          <section className="trending-courses rating-instructor-four rating-instructor-five blog-latest">
            <div className="container">
              <div className="section-header section-head-left ">
                <div className="view-all ">
                  <a href="#/products">
                    {t("View all")}
                    <i className="fa fa-arrow-right" aria-hidden="true" />
                  </a>
                </div>
              </div>
              <div className="instructor-four-face aos ">
                <div className="row">
                  <div className="col-lg-4 col-md-6 d-flex align-items-center">
                    <div className="section-header section-head-left ">
                      <h2>{t("Shop from our fish!")}</h2>
                      <h4>
                        {t("Explore the diverse selection of fish we offer")}
                      </h4>
                      <p className="sub-title">
                        {t(
                          "Dive into a World of Exceptional Fish: Discover and Purchase a Wide Range of Stunning Fish Species."
                        )}
                      </p>
                    </div>
                  </div>
                  {fishes.map((fishe) => (
                    <div
                      className="col-lg-4 col-md-6"
                      key={fishe._id}
                      sm={12}
                      md={6}
                      lg={4}
                      xl={3}
                    >
                      <Fishe fishe={fishe} />
                    </div>
                  ))}
                </div>
                <Pagination
                  pages={pages}
                  page={page}
                  handlePageChange={handlePageChange}
                />
              </div>
            </div>
          </section>

          <section className="new-course subscribe-course">
            <div className="container">
              <div className="new-course-background">
                <div className="row">
                  <div className="col-lg-9 col-md-6 d-flex align-items-center">
                    <div className="every-new-course aos ">
                      <div className="new-course-text">
                        <h1>
                          {t("Sign Up For Our Newsletter")}
                          <br />
                        </h1>
                        <p className="page-sub-text ">
                          {t("Subscribe for Newsletter.")}
                        </p>
                      </div>
                      <div className="course-mail">
                        <div className="join-arrow">
                          <img src="assets/img/join-arrow.png" alt />
                        </div>
                        <form action="#">
                          <div className="input-group mb-3 subscribe-form">
                            <input
                              type="text"
                              className="form-control course-text-bg"
                              placeholder={t("Enter your mail")}
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                            />
                            <button
                              type="button"
                              className="btn bg-course-subscribe"
                              onClick={handleSubscribe}
                            >
                              {t("Subscribe")}
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6">
                    <div
                      className="new-course-img aos"
                      style={{ marginTop: "105px" }}
                    >
                      <div className="hero">
                        <img src="assets/img/fish-modified.png" alt />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <Footer />
        </div>
      )}
    </div>
  );
}
export default Index;
