import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Header from "../../components/Header";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../actions/userActions";
import { useNavigate, useLocation } from "react-router-dom";

function Index() {
   const [email, setEmail] = useState("");
   const [password, setPassword] = useState("");
   const dispatch = useDispatch();
   const location = useLocation();
   const redirect = location.search ? location.search.split("=")[1] : "/";

   const submitHandler = (e) => {
     e.preventDefault();
     dispatch(login(email, password));
   };

   const userLogin = useSelector((state) => state.userLogin);
   const { error, loading, userInfo } = userLogin;

   const navigate = useNavigate();
   useEffect(() => {
     if (userInfo) {
       navigate(redirect);
     }
   }, [navigate, userInfo, redirect]);
  return (
    <div
      className="account-page main-wrapper"
      style={{ background: "#F1F7FF" }}
    >
      <div className="container login-body">
        <div className="container login-wrapper">
          <div className="loginbox ">
            <div className="login-left">
              <img
                className="img-fluid"
                src="assets/img/home-art1.jpg"
                alt="Logo"
              />
            </div>
            <div className="login-right">
              <div className="login-right-wrap">
                <h1>Login</h1>
                <p className="account-subtitle">Access to our dashboard</p>
                <form onSubmit={submitHandler}>
                  <div className="form-group">
                    <input
                      className="form-control"
                      placeholder="Email"
                      type="email"
                      required
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <input
                      className="form-control"
                      type="password"
                      placeholder="Password"
                      required
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <button
                      className="btn btn-primary btn-block w-100"
                      type="submit"
                      style={{ background: "#002D7F" }}
                    >
                      Login
                    </button>
                  </div>
                </form>
                <br />{" "}
                <div class={error ? "alert alert-danger" : " "}>{error}</div>
                <div className="text-center forgotpass">
                  
                </div>
                <div className="text-center dont-have">
                  Don’t have an account? <Link to="/register">Register</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Index;
