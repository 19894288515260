import React, { useState, useEffect, useContext } from "react";
import Header from "../../components/Header/index";
import Footer from "../../components/Footer/index";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import P404 from "../p404";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import { addTocart, removeFromCart } from "../../actions/cartAction";
import { useTranslation } from "react-i18next"; // Importing useTranslation hook
import { LanguageContext } from "../../components/TradeNameLangague/LanguageContext";

function Index() {
  const { t } = useTranslation(); // Initializing the useTranslation hook

  const { id } = useParams();
  const location = useLocation();
  const qty = location.search ? Number(location.search.split("=")[1]) : 1;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart);
  const { cartItems } = cart;
  console.log("cartItems :", cartItems);
  const { selectedLanguage } = useContext(LanguageContext);

  useEffect(() => {
    if (id) {
      dispatch(addTocart(id, qty));
    }
  }, [dispatch, id, qty]);
  const removeFromCartHandler = (id) => {
    dispatch(removeFromCart(id));
  };

  const checkoutHandler = () => {
    navigate("/shipping");
  };

  return (
    <div
      className="main-wrapper"
      style={{ backgroundColor: "#f1f7ff", minHeight: "700px" }}
    >
      <Header />
      <div className="content">
        <div className="container">
          <div className="comp-header">
            <h3 className="comp-title">{t("Cart")}</h3> {/* Translate "Cart" */}
            <div className="line"></div>
          </div>
          {cartItems.length === 0 ? (
            <div variant="info">
              {t("Your cart is empty")}{" "}
              <Link className="a" to="/">
                {t("Go Back")}
              </Link>{" "}
              {/* Translate "Your cart is empty" and "Go Back" */}
            </div>
          ) : (
            <>
              <div className="card card-table">
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table table-hover table-center mb-0">
                      <thead>
                        <tr>
                          <th></th>
                          <th>{t("quantity in ton")}</th>{" "}
                          {/* Translate "quantity in kg" */}
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {cartItems.map((item) => (
                          <tr key={item.fishe}>
                            {" "}
                            {/* Added a unique key for each item */}
                            <td>
                              <h2 className="table-avatar">
                                <Link
                                  to={`/products/${item.fishe}`}
                                  className="avatar avatar-xl me-2"
                                >
                                  <img
                                    className="avatar-img rounded-circle"
                                    src={item.image}
                                    alt={item.trade_name}
                                  />
                                </Link>
                                <Link to={`/products/${item.fishe}`}>
                                  {selectedLanguage === "fr"
                                    ? item.trade_name_fr
                                    : selectedLanguage === "es"
                                    ? item.trade_name_es
                                    : item.trade_name}
                                </Link>
                              </h2>
                            </td>
                            <td>
                              <div style={{ width: "120px" }}>
                                <input
                                  type="number"
                                  className="form-control text-center"
                                  value={item.qty}
                                  onChange={(e) =>
                                    dispatch(
                                      addTocart(
                                        item.fishe,
                                        Number(e.target.value)
                                      )
                                    )
                                  }
                                />
                              </div>
                            </td>
                            <td>
                              <div className="actions">
                                <Button
                                  className="btn btn-danger"
                                  onClick={() =>
                                    removeFromCartHandler(item.fishe)
                                  }
                                >
                                  <i className="fas fa-times" />
                                </Button>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="submit-section proceed-btn text-end">
                <Link
                  to="/shipping"
                  className="btn btn-primary submit-btn"
                  disabled={cartItems.length === 0}
                  onClick={checkoutHandler}
                >
                  {t("Proceed To Checkout")}{" "}
                  {/* Translate "Proceed To Checkout" */}
                </Link>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default Index;
