import React, { useState, useEffect } from "react";
import Sidebar from "../../../../components/sidebar";
import Header from "../../../../components/Header";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";

import {
  listOrders,
  deleteOrder,
  getOrderDetails,
} from "../../../../actions/orderAction";
import { useNavigate, useLocation ,Link} from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCreditCard,
  faShrimp,
  faFish,
  faMoneyBillTransfer,
} from "@fortawesome/free-solid-svg-icons";

function Index() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const orderList = useSelector((state) => state.orderList);
  const { loading, error, orders } = orderList;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const orderDelete = useSelector((state) => state.orderDelete);
  const { success } = orderDelete;

  useEffect(() => {
    if (userInfo && userInfo.data.isAdmin) {
      dispatch(listOrders());
    } else {
      navigate("/login");
    }
  }, [dispatch, navigate, success, userInfo]);

  const getbyId = (id) => {
    console.log(id);
    dispatch(getOrderDetails(id));
  };
  const orderDetails = useSelector((state) => state.orderDetails);
  const { order } = orderDetails;
  const deleteHandler = () => {
   // if (window.confirm("Are you sure you want to delete this order")) {
     
   if(order && order.data)
   {dispatch(deleteOrder(order.data._id));}
  //  }
  };
    const { t, i18n } = useTranslation();

  return (
    <body>
      <div className="main-wrapper">
        <Header />
        <br />

        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <Sidebar />
              <div className="col-md-7 col-lg-8 col-xl-9">
                <div className="row">
                  <div className="col-md-12">
                    <h4 className="mb-4">{t("list of orders")} </h4>
                    <div className="card card-table">
                      <div className="card-body">
                        <div className="table-responsive">
                          <table className="table table-hover table-center mb-0">
                            <thead>
                              <tr>
                                <th>ID</th>
                                <th>User</th>
                                <th className="text-center">Date</th>
                                <th className="text-center">ACTION</th>
                              </tr>
                            </thead>
                            <tbody>
                              {orders &&
                                orders.data &&
                                orders.data.map((order) => (
                                  <tr>
                                    <td>{order._id}</td>
                                    <td>
                                      <h2 className="table-avatar">
                                        <a href="/">
                                          {order.user && order.user.name}
                                          <span>
                                            <span
                                              className="__cf_email__"
                                              data-cfemail="3b4f424954555e4954595e494f487b5a"
                                            >
                                              {order.user && order.user.email}
                                            </span>
                                          </span>
                                        </a>
                                      </h2>
                                    </td>
                                    <td className="text-center">
                                      {moment(order?.created_at).format(
                                        "DD-MM-YYYY hh:mm"
                                      )}{" "}
                                    </td>{" "}
                                    <td className="text-center">
                                      <div className="actions">
                                        <Link
                                          to={`/order/${order._id}`}
                                          className="btn btn-sm bg-info-light"
                                        >
                                          <i className="far fa-eye" /> View
                                        </Link>{" "}
                                        &nbsp;
                                        <a
                                          className="btn btn-sm bg-danger-light"
                                          data-bs-toggle="modal"
                                          href="#delete_modal"
                                          onClick={() => getbyId(order._id)}
                                        >
                                          <i className="fas fa-trash-alt" />{" "}
                                          Delete
                                        </a>
                                      </div>
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="delete_modal"
        aria-hidden="true"
        role="dialog"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content" style={{ textAlign: "center" }}>
            <div className="modal-body">
              <div className="form-content p-2">
                <h4 className="modal-title">Delete</h4>
                <p className="mb-4">Are you sure want to delete?</p>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => deleteHandler()}
                  data-bs-dismiss="modal"
                >
                  Delete{" "}
                </button>
                <button
                  type="button"
                  className="btn btn-danger"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </body>
  );
}

export default Index;
